const resource = '/api/translation'

export default (ctx, $axios) => ({
  get (url) {
    return $axios.get(`${resource}/getall`, {
      params: {
        url: decodeURIComponent(url)
      }
    })
  }
})