<template>
  <div>
    <header
      class="o-header"
      :class="{ 
        '-scrolled': headerScrolled, 
        '-active': isActive }"
    >
      <div v-if="!$isNullOrUndefined(preHeaderItems)" class="m-pre-header px-8 d-none d-lg-block ">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <nav class="m-pre-header__nav">
                <base-link
                  v-for="(preHeaderItem, index) in preHeaderItems"
                  :key="index"
                  class="m-pre-header__link"
                  :link="preHeaderItem.url"
                >
                  {{ preHeaderItem.meta.title }}
                </base-link>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-secondary px-2 px-md-4 px-lg-8">
        <div class="container">
          <div class="row align-items-center o-header__primary">
            <!-- Logo -->
            <div class="col-7 col-lg-3 d-flex align-items-center">
              <the-mobile-navigation v-if="!$ua.isFromCrawler()" class="d-block d-lg-none" :scrolled="headerScrolled" />
              <the-logo class="o-header__logo" />
            </div>

            <!-- Search -->
            <div class="col-6 d-none d-lg-flex">
              <search-bar
                :placeholder="$t('SearchPlaceholder', 'Search for items')"
                :is-body-scrolled="isScrolled"
                class="o-header__search"
              />
            </div>

            <!-- controls -->
            <div class="col col-lg-3">
              <div class="o-header__controls">
                <!-- Search 
                  <div class="o-header__icon -search d-lg-none">
                    <base-icon icon="search" />
                  </div>
                -->

                <!-- Login / User -->
                <base-link
                  v-if="$isNotNullOrUndefined(userPage) && !isLoggedIn"
                  class="o-header__icon -login"
                  :quick-link="userPage"
                  :link="userPage.link && !userPage.isCheckoutLink ? removeHostFromUrl(userPage.link.url) : null"
                  :target="userPage.link ? userPage.link.target : null"
                  @mouseover.native="isHoveringUserIcon = true"
                  @mouseleave.native="isHoveringUserIcon = false"
                >
                  <base-icon v-if="$isNotNullOrUndefined(userPage.icon)" :icon="userPage.icon" class="" />
                  <span class="d-none d-lg-inline">{{ userPage.link.name }}</span>
                </base-link>
                <dropdown
                  v-if="isLoggedIn"
                  :show-chevron="false"
                  show-custom-icon
                  :custom-icon="isImpersonating ? 'user-impersonation' : userPage.icon"
                  class="o-header__icon -login"
                  :class="{ '-primary': isLoggedIn }"
                  @mouseover.native="isHoveringUserIcon = true"
                  @mouseleave.native="isHoveringUserIcon = false"
                >
                  <base-link
                    v-if="$isNotNullOrUndefined(userPage)"
                    class="m-dropdown__option py-2 px-3 text-decoration-none d-flex"
                    :link="userPage.link && !userPage.isCheckoutLink ? removeHostFromUrl(userPage.link.url) : null"
                    :target="userPage.link ? userPage.link.target : null"
                  >
                    <span class="semibold">{{ userPage.link.name }}</span>
                  </base-link>
                  <template v-for="(userMenuItem, index) in userMenu">
                    <base-link
                      v-if="isAllowedToSeeUserItem(userMenuItem)"
                      :key="index"
                      class="m-dropdown__option py-2 px-3 text-decoration-none d-flex"
                      :link="removeHostFromUrl(userMenuItem.url)"
                    >
                      <span class="semibold">{{ userMenuItem.meta.title }}</span>
                    </base-link>
                  </template>
                  <base-link
                    class="m-dropdown__option py-2 px-3 text-decoration-none d-flex"
                    @click.native="logout"
                  >
                    <span class="semibold">{{ $t('logOut', 'Log ud') }}</span>
                  </base-link>
                </dropdown>

                <!-- Basket -->
                <div class="o-header__icon -basket">
                  <basket-button @click="miniBasketIsVisible = !miniBasketIsVisible" />
                  <span class="d-none d-lg-inline">Kurv</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <the-desktop-navigation v-if="!$ua.isFromCrawler()" />
              <navigation-tree v-else />
            </div>
            <div class="col-12 o-header__mobile-search d-block d-lg-none">
              <search-bar
                :placeholder="$t('SearchPlaceholder', 'Search for items')"
                :portal-target="'search-dropdown-mobile'"
                :is-body-scrolled="isScrolled"
                class="o-header__search"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Portals -->
      <portal-target name="megamenu" />
      <portal-target name="search-dropdown" />
      <portal-target name="search-dropdown-mobile" />
    </header>
    <sidebar
      :visible="miniBasketIsVisible"
      :title="miniBasketHeadline"
      :body-scroll="isMobile ? false : true"
      :scrolled="headerScrolled"
      @close="miniBasketIsVisible = false"
    >
      <div
        v-if="isBasketEmpty"
        class="pb-8"
      >
        {{ $t('EmptyBasket', 'Din kurv er tom. Tilføj produkter fra vores brede sortiment') }}
      </div>
      <mini-basket v-if="!isBasketEmpty" :track-basket-views="true" />
      <template v-slot:content-bottom>
        <div
          v-if="!isBasketEmpty"
          class="px-4 px-md-8 py-5"
        >
          <order-summary-punch-out v-if="isPunchOutUser" />
          <order-summary
            v-else
            :summary="basket.orderSummary"
            :button-text="$t('goToCheckout', 'Gå til kassen')"
            :button-link="checkoutPage"
            :orderlines="basket.orderLines"
          />
        </div>
      </template>
    </sidebar> 
    
    <!-- Sidebar -->
    <div
      class="impersonation"
      :class="{'-visible': isHoveringUserIcon && isImpersonating}"
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            {{ $t('headerImpersonationLoggedInMessage', 'Du er nu logget ind som {customerName}', { customerName }) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseLink from '@/components/atoms/BaseLink'
import OrderSummary from '@/components/molecules/ShopCommerce/OrderSummary'
import OrderSummaryPunchOut from '@/components/molecules/ShopCommerce/OrderSummaryPunchOut';
import TheDesktopNavigation from '@/components/molecules/TheDesktopNavigation';
import TheMobileNavigation from '@/components/molecules/TheMobileNavigation';
import BasketButton from '@/components/molecules/BasketButton';
import SearchBar from '@/components/molecules/SearchBarMain';
import BaseIcon from '@/components/atoms/BaseIcon';
import Sidebar from '@/components/organisms/Sidebar';
import MiniBasket from '@/components/organisms/ShopCommerce/MiniBasket';
import LoginMixin from '@/mixins/loginMixin';
import Dropdown from '@/components/molecules/Dropdown';
import TheLogo from '@/components/molecules/TheLogo.vue';
import NavigationTree from '@/components/molecules/NavigationTree';
import {
  mapMobileObserver,
  unMapMobileObserver
} from '@/utils/mobile-observer';

export default {
  name: 'TheHeader',
  components: {
    TheDesktopNavigation,
    TheMobileNavigation,
    SearchBar,
    BaseIcon,
    Sidebar,
    BasketButton,
    MiniBasket,
    OrderSummary,
    OrderSummaryPunchOut,
    BaseLink,
    Dropdown,
    TheLogo,
    NavigationTree
  },
  mixins: [LoginMixin],
  async fetch () {
    await this.$store.dispatch('cart/basket/fetchBasket', {
      culture: this.$store.getters['site/getCurrentCulture'],
    });

    if (this.user) {
      await this.$store.dispatch('site/fetchUserMenu');
    }
  },
  data () {
    return {
      isScrolled: false,
      isScrolledUp: false,
      isPageTop: false,
      previousScrollPosition: 0,


      miniBasketIsVisible: false,
      isHoveringUserIcon: false,
      intervalId: null,
    };
  },
  computed: {
    // General
    ...mapMobileObserver(),
    siteSettings () {
      return this.$store.getters['site/getSiteSettings'];
    },

    // Navigation
    preHeaderItems () {
      return this.siteSettings?.secondaryNavigation;
    },
    isActive () {
      return this.$store.getters['navigation/getActiveState'];
    },

    // Scroll Behavior
    headerScrolled () {
      return this.isScrolled && !this.isScrolledUp && !this.isPageTop && !this.navigationActive;
    },

    // Basket
    basket () {
      return this.$store.getters['cart/basket/getBasket'];
    },
    totalQuantity () {
      return this.$isNotNullOrUndefined(this.basket.orderSummary)
        ? this.basket.orderSummary.totalQuantity
        : 0;
    },
    miniBasketHeadline () {
      return `${this.$t('yourShoppingCart', 'Din indkøbskurv')} (${this.totalQuantity} ${this.goodsTranslation})`;
    },
    goodsTranslation () {
      return this.totalQuantity > 1
        ? this.$t('goodsPlural', 'varer')
        : this.$t('goodsSingular', 'vare');
    },
    checkoutPage () {
      return this.$store.getters['site/getSiteSettings']?.checkoutPage?.url;
    },
    isBasketEmpty () {
      return this.$store.getters['cart/basket/isBasketEmpty'];
    },

    // Search
    searchResultsCount () {
      return this.$store.getters['search/productSearch/getTotalCount'];
    },
    currentSearchQuery () {
     return this.$store.getters['search/productSearch/getCurrentQuery'];
    },

    // User
    userPage () {
      const loginLink = this.siteSettings?.loginLink;
      const myAccountLink = this.siteSettings?.myAccountLink;

      if(this.$isNotNullOrUndefined(loginLink) && this.$isNotNullOrUndefined(myAccountLink)) {
        if(this.isLoggedIn) {
          myAccountLink.name = this.$t('myAccount', 'Min konto');
          return { 'icon': 'user', 'link': myAccountLink };
        }

        loginLink.name = this.$t('login', 'Log ind');
        return { 'icon': 'user', 'link': loginLink };
      } else {
        return null
      }
    },
    userMenu () {
      return this.$store.state.site.userMenu;
    },
    isImpersonating () {
      return this.$store.getters['user/user/isImpersonating'];
    },
    isPunchOutUser () {
      return this.$store.getters['user/user/isPunchOutUser'];
    },
    isConsultant () {
      return this.$store.getters['user/user/isConsultant'];
    },
    hasMultipleAccounts () {
      return !!this.user.properties?.customerNumbers;
    },
    
    hasCustomerNumber () {
      return this.$store.getters['user/user/hasCustomerNumber'];
    },
    hasAgreementNo () {
      return this.user?.accountProperties?.agreementNo;
    },
    customerName () {
      return this.user?.accountProperties?.customerName
    },
    getLoginPageLink () {
      return this.siteSettings?.loginLink?.url;
    }
  },
  watch: {
    isLoggedIn: {
      handler: async function (value) {
        if(value) {
          this.intervalId = setInterval(this.checkLoginStatus, 30000);
        }
      }
    }
  },
  created () {
    if (process.client) {
      window.addEventListener('scroll', this.handleScroll);
    }
  },
  mounted () {
    if (this.isLoggedIn && !this.intervalId) {
      this.intervalId = setInterval(this.checkLoginStatus, 30000);
    }
  },
  destroyed () {
    if (process.client) {
      window.removeEventListener('scroll', this.handleScroll);
    }
    unMapMobileObserver();
  },
  methods: {
    
    handleScroll () {
      const scrollPosition = window.scrollY;
      const isScrollingUp = scrollPosition < this.previousScrollPosition;

      this.isScrolled = true;

      // Case 0: Navigation is active so the header should stay visible after scrolling and exiting the navigation
      if (this.navigationActive) {
        this.isPageTop = true;
        return;
      }
      // Case 1: Initial loading of the page
      if (scrollPosition === 0) {
        this.isPageTop = true;
        this.isScrolledUp = false;
      }
      // Case 2: Scrolling up
      else if (isScrollingUp) {
        this.isScrolledUp = true;
        this.isPageTop = false;
      } else {
        this.isScrolledUp = false;
        this.isPageTop = false;
      }

      this.previousScrollPosition = scrollPosition;
    },
    removeHostFromUrl (url){
      return url.replace('https://' + this.hostName, '')
                      .replace('http://' + this.hostName, '')
    },



    // User
    async checkLoginStatus () {
      try {
        await this.$repositories.authentication.isLoggedIn(), Promise.resolve();
      }
      catch {
        this.$store.dispatch('user/user/setUser', '');
        if(this.intervalId) {
          this.$router.push(this.getLoginPageLink);
        }
        this.cleanInterval();
      }
    },
    cleanInterval () {
      clearInterval(this.intervalId);
      this.intervalId = null;
    },
    logout () {
      this.$store.dispatch('user/user/logout');
      this.cleanInterval();
    },
    isAllowedToSeeUserItem (userMenuItem) {
      if ((userMenuItem?.meta?.template === 'favoritePage' || userMenuItem?.meta?.template === 'latestBoughtProductsPage') && !this.hasCustomerNumber) {
        return false;
      }

      if ((userMenuItem?.meta?.template === 'assortmentsPage') && !this.hasAgreementNo) {
        return false;
      }

      if (userMenuItem?.meta?.template === 'impersonationPage' && !this.isConsultant && !this.hasMultipleAccounts) {
        return false;
      }

      return true;
    },
  }
};
</script>
