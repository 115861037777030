const resource = '/api/content'
const resourceSearch = '/shop-api/search/content'

export default (ctx, $axios) => ({
  byUrl (url) {
    if (typeof url === 'undefined' || url === '') {
      url = decodeURIComponent(ctx.route.path)
    }

    return $axios.get(`${resource}`, {
      params: {
        url
      },
      validateStatus: false
    })
  },

  byQuery (query, culture, itemsPerPage=10, currentPage=1) {
    return $axios.post(`${resourceSearch}/byquery`, {
      query: query,
      cultureName: culture,
      itemsPerPage: itemsPerPage,
      pageNumber: currentPage,

    });
  },

  getChildren (culture, guid, sortOrder='', orderByDescending='asc') {
    return $axios.$get(`${resource}/getchildren/${guid}/${culture}`, {
      params: {
        sortOrder,
        orderByDescending
      }
    });
  },
  byGuid (guid, culture) {
    return $axios.get(`${resource}/${guid}/${culture}`);
  },
  getPropertyValue (guid, culture, propertyJsonPath, includeAncestors=true) {
    return $axios.get(`${resource}/propertyValue/${guid}/${culture}`, {
      params: {
        includeAncestors,
        propertyJsonPath
      }
    });
  }
})
