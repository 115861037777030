<template>
  <div
    :aria-expanded="open ? 'true' : 'false'"
    :aria-owns="'lbox_' + _uid"
    aria-autocomplete="none"
    role="combobox"
    tabindex="0"
    :class="{
      'm-dropdown--is-active': isActive,
      'm-dropdown--is-selected': isSelected,
      'm-dropdown--is-required': required,
      'm-dropdown--is-disabled': disabled,
      'm-dropdown--custom':showCustomIcon,
    }"
    class="m-dropdown"
    @click="toggle($event)"
    @keyup.space="openHandler"
    @keyup.up="move(-1)"
    @keyup.down="move(1)"
    @keyup.enter="enter($event)"
  >
    <div v-click-outside="closeHandler">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="m-dropdown__selected m-dropdown-option" v-html="html" />
      <slot name="label">
        <div v-if="label" class="m-dropdown__label">
          {{ label }}
        </div>
      </slot>
      <slot name="icon">
        <base-icon v-if="showChevron" icon="chevron-down" class="m-dropdown__chevron" />
        <base-icon v-if="showCustomIcon" :icon="customIcon" class="m-dropdown__chevron" />
      </slot>
      <overlay :visible="open" class="m-dropdown__overlay d-none" />
      <transition name="m-dropdown">
        <div v-show="open" class="m-dropdown__dropdown">
          <!--  m-dropdown__option -->
          <ul :style="{ maxHeight }" class="m-dropdown__options">
            <slot />
          </ul>
        </div>
      </transition>
    </div>
    <div v-if="valid !== undefined" class="m-dropdown__error-message">
      <transition name="fade">
        <div v-if="!valid">
          <!-- @slot Custom error message of form select -->
          <slot name="error-message" v-bind="{ errorMessage }">
            {{ errorMessage }}
          </slot>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import Overlay from '@/components/atoms/Overlay'
import BaseIcon from '@/components/atoms/BaseIcon'
import { clickOutside } from '@/utils/click-outside-directive';

export default {
  name: 'Dropdown',
  components: {
  BaseIcon,
  Overlay
  },
  directives: {
    clickOutside,
  },
  model: {
    prop: 'selected',
    event: 'change',
  },
  props: {
    /**
     * Select field label
     */
    label: {
      type: String,
      default: '',
    },
    /**
     * Selected item value
     */
    selected: {
      type: [String, Number, Object],
      default: '',
    },
    /**
     * m-Dropdown list size
     */
    size: {
      type: Number,
      default: 7,
    },
    /**
     * Required attribute
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Validate value of form input
     */
    valid: {
      type: Boolean,
      default: undefined,
    },
    /**
     * Disabled status of form select
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Error message value of form select. It will be appeared if `valid` is `true`.
     */
    errorMessage: {
      type: String,
      default: 'This field is not correct.',
    },
    showChevron: {
      type: Boolean,
      default: true
    },
    showCustomIcon: {
      type: Boolean,
      default: false
    },
    customIcon: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      open: false,
      options: [],
      indexes: {},
      optionHeight: 0,
    };
  },
  computed: {
    index: {
      get () {
        const stringified = this.indexes[JSON.stringify(this.selected)];
        if (typeof stringified === 'undefined') {
          return -1;
        }
        return stringified;
      },
      set (index) {
        this.$emit('change', this.options[index].value);
      },
    },
    html () {
      if (this.index < 0) return;
      return this.options[this.index].html;
    },
    maxHeight () {
      if (!this.size) return;
      return `${this.optionHeight * this.size}px`;
    },
    isActive () {
      return this.open;
    },
    isSelected () {
      return this.selected;
    },
  },
  watch: {
    open: {
      immediate: true,
      handler: function (visible) {
        if (visible) {
          this.$nextTick(() => {
            this.optionHeight = this.$slots.default[0].elm.offsetHeight;
          });
        }
      },
    },
  },
  created: function () {},
  mounted: function () {
    const options = [];
    const indexes = {};
    let i = 0;
    if (!this.$slots.default) return;
    this.$on('update', this.update);
    this.$slots.default.forEach((slot) => {
      if (!slot.tag) return;
      options.push({
        ...slot.componentOptions.propsData,
        html: slot.elm.innerHTML,
      });
      indexes[JSON.stringify(slot.componentOptions.propsData.value)] = i;
      i++;
    });
    this.options = options;
    this.indexes = indexes;
  },
  beforeDestroy: function () {
    this.$off('update', this.update);
  },
  methods: {
    update (index) {
      this.index = index;
    },
    move (payload) {
      const optionsLength = this.options.length;
      let index = this.index;
      index += payload;
      if (index < 0) index = 0;
      if (index >= optionsLength) index = optionsLength - 1;
      this.index = index;
    },
    enter () {
      this.toggle();
    },
    toggle (event) {
      if (
        (this.$refs.cancel &&
          event &&
          event.target.contains(this.$refs.cancel.$el)) ||
        this.disabled
      ) {
        return;
      }
      this.open = !this.open;
    },
    openHandler () {
      this.open = true;
    },
    closeHandler () {
      this.open = false;
    },
  },
};
</script>
