
export default async function (ctx) {
  const pageUid = ctx.store.getters['content/content/getPageUid']
  const culture = ctx.store.getters['site/getCurrentCulture'];

  ctx.store.dispatch('content/content/fetchTheme', {
    pageUid,
    culture,
    propertyJsonPath: 'advanced|theme',
    includeAncestors: true,
  })

  ctx.store.dispatch('content/content/fetchThemeImage', {
    pageUid,
    culture,
    propertyJsonPath: 'advanced|themeImage',
    includeAncestors: true,
  })

  return ctx;
}