const resource = '/api/settings'

export default (ctx, $axios) => ({
  settingsByUrl (url) {
    if (typeof url === 'undefined' || url === '') {
      url = decodeURIComponent(ctx.route.path)
    }

    return $axios.get(`${resource}`, {
      params: {
        url
      }
    })
  }
})