import { getRandomString } from '@/utils/random'

/* What this plugin does:
 * Generates a NZVisitorId-cookie if Cookiebot markering consent
 * was accepted and if NZVisitorId-cookie doesn't exist already.
 * Also remove NZVisitorId-cookie if Cookiebot markering consent
 * was declined.
 *
 * The vistor ID will be used for tracking by the backend.
 */

const updateVisitorId = (ctx) => {
  if (window.Cookiebot?.consent?.marketing) {
    // Get cookie
    let visitorId = ctx.$cookies.get('NZVisitorId');

    if (typeof visitorId === 'undefined' || !visitorId.length) {
      // Set cookie
      visitorId = getRandomString(8);
      ctx.$cookies.set('NZVisitorId', visitorId, {
        path: '/',
        maxAge: 365 * 24 * 60 * 60 * 1000
      });
    }
  }
  else {
    ctx.$cookies.remove('NZVisitorId');
  }
}

export default ( ctx ) => {
  window.addEventListener('CookiebotOnConsentReady', updateVisitorId.bind(this, ctx), false);
  window.addEventListener('CookiebotOnAccept', updateVisitorId.bind(this, ctx), false);
  window.addEventListener('CookiebotOnDecline', updateVisitorId.bind(this, ctx), false);
}
