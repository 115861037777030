export default function ({ $axios, store }) {

  // Set auth token on all request while logged in
  $axios.onRequest(request => {
    // If the URL doesnt start with '/' it's probably not a Brønnum endpoint
    if (!request?.url?.startsWith('/')) return;
    let user = store.state?.user?.user;
    if (user?.user) {
      let authToken = `Bearer ${user?.authToken}`;
      if (!request?.headers?.common) {
        request.headers = {
          common: {}
        }
      }
      request.headers.common['Authorization'] = authToken;
    }
    return request;
  });

}