import SiteRepository from '~/api/repositories/SiteRepository'
import ContentRepository from '~/api/repositories/ContentRepository'
import TranslationRepository from '~/api/repositories/TranslationRepository'
import NavigationRepository from '~/api/repositories/NavigationRepository'
import ProductRepository from '~/api/repositories/ShopCommerce/ProductRepository'
import ProductsRepository from '~/api/repositories/ShopCommerce/ProductsRepository'
import PricesRepository from '~/api/repositories/ShopCommerce/PricesRepository'
import OrderRepository from '~/api/repositories/ShopCommerce/OrderRepository'
import BasketRepository from '~/api/repositories/ShopCommerce/BasketRepository'
import CategoryRepository from '~/api/repositories/ShopCommerce/CategoryRepository'
import BillingRepository from '~/api/repositories/ShopCommerce/BillingRepository'
import ShippingRepository from '~/api/repositories/ShopCommerce/ShippingRepository'
import AssetsRepository from '~/api/repositories/ShopCommerce/AssetsRepository'
import PaymentRepository from '~/api/repositories/ShopCommerce/PaymentRepository'
import StockRepository from '~/api/repositories/ShopCommerce/StockRepository'
import SuggestionsRepository from '~/api/repositories/ShopCommerce/SuggestionsRepository'
import CurrencyRepository from '~/api/repositories/ShopCommerce/CurrencyRepository';
import AuthenticationRepository from '~/api/repositories/AuthenticationRepository'
import FormRepository from '~/api/repositories/FormRepository'
import UsersRepository from '~/api/repositories/UsersRepository'
import ActiveCampaignRepository from '~/api/repositories/ActiveCampaignRepository'
import PunchOutRepository from '~/api/repositories/PunchOutRepository';
import FavoriteRepository from '~/api/repositories/ShopCommerce/FavoriteRepository';
import LatestBoughtProductsRepository from '~/api/repositories/ShopCommerce/LatestBoughtProductsRepository';


export default (ctx, $axios) => ({
  site: SiteRepository(ctx, $axios),
  content: ContentRepository(ctx, $axios),
  translation: TranslationRepository(ctx, $axios),
  navigation: NavigationRepository(ctx, $axios),
  product: ProductRepository(ctx, $axios),
  products: ProductsRepository(ctx, $axios),
  prices: PricesRepository(ctx, $axios),
  order: OrderRepository(ctx, $axios),
  basket: BasketRepository(ctx, $axios),
  category: CategoryRepository(ctx, $axios),
  billing: BillingRepository(ctx, $axios),
  shipping: ShippingRepository(ctx, $axios),
  assets: AssetsRepository(ctx, $axios),
  payment: PaymentRepository(ctx, $axios),
  stock: StockRepository(ctx, $axios),
  authentication: AuthenticationRepository(ctx, $axios),
  form: FormRepository(ctx, $axios),
  users: UsersRepository(ctx, $axios),
  activeCampaign: ActiveCampaignRepository(ctx, $axios),
  suggestions: SuggestionsRepository(ctx, $axios),
  punchOut: PunchOutRepository(ctx, $axios),
  favorite: FavoriteRepository(ctx, $axios),
  currency: CurrencyRepository(ctx, $axios),
  latestBoughtProducts: LatestBoughtProductsRepository(ctx, $axios),
})
