
<template>
  <base-button
    class="m-add-to-cart -secondary"
    aria-label="add-to-cart"
    role="button"
    :disabled="disabled"
    @click="addToCart"
  >
    <base-loader v-if="showLoader" />
    <base-icon
      v-else-if="showTick"
      class="margin-0-auto text-white "
      icon="check"
    />
    <template v-else>
      <base-icon
        v-if="useIcon"
        icon="bronnum-basket"
      />
      <span v-else>{{ $t('AddToBasket', 'Læg i kurv') }}</span>
    </template>
  </base-button>
</template>

<script>
import BaseButton from '@/components/atoms/BaseButton';
import BaseLoader from '@/components/atoms/BaseLoader';
import BaseIcon from '@/components/atoms/BaseIcon';
import LoginMixin from '@/mixins/loginMixin';

export default {
  name: 'AddToCart',
  components: {
    BaseButton,
    BaseLoader,
    BaseIcon
  },
  mixins: [LoginMixin],
  props: {
    quantity: {
      type: Number,
      required: true,
    },
    stockLevel: {
      type: Number,
      default: Infinity,
    },
    kolliNumber: {
      type: Number,
      default: 1,
    },
    isOrderingItem: {
      type: Boolean,
      default: false,
    },
    isRestsalgItem: {
      type: Boolean,
      default: false,
    },
    sku: {
      type: String,
      required: true,
    },
    /**
     * Boolean to indicate whether product
     * can be added to cart
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    useIcon: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      showLoader: false,
      showTick: false,
      excessQuantity: 0,
    }
  },
  computed: {
    currentCulture () {
      return this.$store.getters['site/getCurrentCulture'];
    },
    currency () {
      return this.$store.getters['currency/getCurrentCurrency'];
    },
    customProperties () {
      return {
        orderingItem: this.isOrderingItem,
        restsalgItem: this.isRestsalgItem,
      }
    },
    getLoginPageLink () {
      return this.$store.getters['site/getSiteSettings']?.loginLink?.url;
    }
  },
  methods: {
    async addToCart () {
      if (this.isLoggedIn) {
        try {
          await this.$repositories.authentication.isLoggedIn(), Promise.resolve();
          this.onAddToCart();
          
        } catch {
          this.$store.dispatch('user/user/setUser', '');
          if(this.intervalId) {
            this.$router.push(this.getLoginPageLink);
          }
        }
      } else {
        this.onAddToCart();
      }
    },

    async onAddToCart () {
      this.showLoader = true;
      console.log(this.currency)
      // if ordered more then in the stock, send 2 separate requests
      if (this.stockLevel != 0 && this.stockLevel < this.quantity) {
        const kolliInStock = Math.floor(this.stockLevel / this.kolliNumber);
        const totalStockItems = kolliInStock * this.kolliNumber;
        this.excessQuantity = this.quantity - totalStockItems;

        if (this.excessQuantity > 0) {
          // add message in the basket
          this.customProperties.outOfStock = true;

          await this.$store.dispatch('cart/basket/addToBasket', {
            culture: this.currentCulture,
            sku: this.sku,
            currency: this.currency,
            quantity: this.excessQuantity,
            properties: this.customProperties
          }).then(()=> {
            this.$parent.onAddToCart();
          });
        }
      }

      this.customProperties.outOfStock = false;

      await this.$store.dispatch('cart/basket/addToBasket', {
        culture: this.currentCulture,
        sku: this.sku,
        currency: this.currency,
        quantity: this.quantity - this.excessQuantity,
        properties: this.customProperties
      }).then(()=> {
        this.$parent.onAddToCart();
      });

      this.showTick = true;
      this.showLoader = false;
      setTimeout(() => {
        this.showTick = false;
      }, 2000);
    }
  },
};
</script>
