const remapProduct = (product) => {
  let mappedProduct = {
    name: product?.title ? product.title?.trim() : (product?.name ? product?.name?.trim() : '') ,
    id: product?.sku,
    price: parseInt(product?.price?.price) < parseInt(product?.price?.regular) ? parseFloat(product?.price?.price).toFixed(2) : parseFloat(product?.price?.regular).toFixed(2),
    brand: product?.properties?.find(prop => prop.name === 'brand')?.value?.trim() ?? 'Unknown Brand',
    category: product?.primaryCategoryName?.trim() ?? '',
    variant: '',
  };

  if (product.quantity) {
    mappedProduct.quantity = product.quantity;
  }

  return mappedProduct;
};

const remapProducts = (products, listName) => {
  let mappedProducts = [];
  products?.forEach((product, index) => {
    let mappedProduct = remapProduct(product);
    mappedProduct.position = index + 1;

    if (listName) {
      mappedProduct.list = listName;
    }

    if(mappedProduct.category === '') {
      mappedProduct.category = listName;
    }

    mappedProducts.push(mappedProduct);
  });

  return mappedProducts;
};

// Product viewed
export const trackProductView = (product, listName = '') => new Promise((resolve) => {
  if (process.client) {
    $nuxt.$gtm.push({
      'event': 'productView',
      'ecommerce': {
        'detail': {
          'actionField': {'list': listName},
          'products': [remapProduct(product)]
        }
      },
      'eventCallback': function () {
        resolve('SUCCESS: productView event successfully fired');
      },
      'eventTimeout': 500
    });
  }
});

// Productlist viewed
export const trackProductImpressions = (products, listName = '', currency = '') => new Promise((resolve) => {
  if (process.client) {
    $nuxt.$gtm.push({
      'event': 'impressionView',
      'ecommerce': {
        'currencyCode': currency, // Local currency is optional.
        'actionField': {'list': listName},
        'impressions': remapProducts(products)
      },
      'eventCallback': function () {
        resolve('SUCCESS: Product Impressions successfully fired');
      },
      'eventTimeout': 500
    });
  }
});

// Product clicked
export const trackProductClick = (product, listName = '') => new Promise((resolve) => {
  if (process.client) {
    $nuxt.$gtm.push({
      'event': 'productClick',
      'ecommerce': {
        'click': {
          'actionField': {'list': listName},
          'products': [remapProduct(product)]
        }
      },
      'eventCallback': function () {
        resolve('SUCCESS: Product click event successfully fired');
      },
      'eventTimeout': 500
    });
  }
});

// Product added to cart
export const trackAddToCart = (product, quantity, currency = '') => new Promise((resolve) => {
  if (process.client) {
    product = remapProduct(product);
    product.quantity = quantity;
    $nuxt.$gtm.push({
      'event': 'addToCart',
      'ecommerce': {
        'currencyCode': currency,
        'add': {
          'products': [product]
        }
      },
      'eventCallback': function () {
        resolve('SUCCESS: Add to cart event successfully fired');
      },
      'eventTimeout': 500
    });
    // facebook addToCart tracking
    const trackingData = {
      content_category: product.category ? product.category : 'Kitchen equipment',
      content_id: product.id,
      content_name: product.name,
      content_type: 'product',
      currency: currency,
      value: product.price
    }
    $nuxt.$fb?.fbq('trackCustom', 'AddToCart', trackingData);
  }
});

// Product removed from cart
export const trackRemoveFromCart = (product, quantity) => new Promise((resolve) => {
  if (process.client) {
    product = remapProduct(product);
    product.quantity = quantity;
    $nuxt.$gtm.push({
      'event': 'removeFromCart',
      'ecommerce': {
        'remove': {
          'products': [product]
        }
      },
      'eventCallback': function () {
        resolve('SUCCESS: Remove from cart event successfully fired');
      },
      'eventTimeout': 500
    });
  }
});

// Track checkout
export const trackCheckout = products => new Promise((resolve) => {

  if (process.client) {
    $nuxt.$gtm.push({
      'event': 'checkout',
      'ecommerce': {
        'checkout': {
          'actionField': {'step': 1},
          'products': remapProducts(products)
        }
      },
      'eventCallback': function () {
        resolve('SUCCESS: Checkout event successfully fired');
      },
      'eventTimeout': 1000
    });
  }
});

// Track basket views
export const trackBasketViews = products => new Promise((resolve) => {

  if (process.client) {

    $nuxt.$gtm.push({
      'event': 'cartView',
      'ecommerce': {
        'cartView': {
          'actionField': {'step': 1},
          'products': remapProducts(products)
        }
      },
      'eventCallback': function () {
        resolve('SUCCESS: Checkout event successfully fired');
      },
      'eventTimeout': 500
    });
  }
});

// Track purchase
export const trackPurchase = (products, basket) => new Promise((resolve) => {
  if (process.client) {
    $nuxt.$gtm.push({
      'event':'purchase',
      'order_value': parseFloat(basket?.orderSummary?.totalPrice).toFixed(2),
      'order_id': basket?.orderNumber,
      'ecommerce': {
        'currencyCode': basket?.orderSummary?.currencyCode,
        'purchase': {
          'actionField': {
            'id': basket?.orderNumber,
            'affiliation': 'Online Store',
            'revenue': parseFloat(basket?.orderSummary?.totalPrice).toFixed(2), // Total transaction value (incl. tax and shipping)
            'tax': parseFloat(basket?.orderSummary?.totalVat).toFixed(2),
            'shipping': parseFloat(basket?.orderSummary?.shippingTotal).toFixed(2),
          },
          'products': remapProducts(products)
        },
      },
      'eventCallback': function () {
        resolve('SUCCESS: Purchase event successfully fired');
      },
      'eventTimeout': 500
    });

    // facebook Purchase tracking
    var productsArray = [];
    var productIds = [];
    products.map(product => {
      productsArray.push({
        id: product.sku,
        quantity: product.quantity,
        name: product.name,
        price: product.price.price
      });
      productIds.push(product.sku);
    })
    
    const trackingData = {
      content_category: 'Kitchen equipment',
      content_type: products.length > 1 ? 'product-group' : 'product',
      currency: basket?.orderSummary?.currencyCode,
      value: basket?.orderSummary?.totalPrice,
      contents: productsArray,
      content_ids: productIds
    }
    $nuxt.$fb?.fbq('trackCustom', 'Purchase', trackingData);
  }
});

// Track filters
export const trackFilters = (category, filter) => new Promise((resolve) => {
  if (process.client) {
    $nuxt.$gtm.push({
      'event': 'product_filter_click',
      'category': category,
      'filter_value': filter,
      'eventCallback': function () {
        resolve('SUCCESS: Filters event successfully fired');
      },
      'eventTimeout': 500
    });
  }
});

export const trackFilterCategories = (category) => new Promise((resolve) => {
  if (process.client) {
    $nuxt.$gtm.push({
      'event': 'product_filter_category_click',
      'category': category,
      'eventCallback': function () {
        resolve('SUCCESS: Filters event successfully fired');
      },
      'eventTimeout': 500
    });
  }
});
