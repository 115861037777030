export const state = () => ({
  siteSettings: {},
  browserIsNotSupported: false,
  languages: [],
  userMenu: [],
});

export const mutations = {
  setSiteSettings (state, data) {
    state.siteSettings = data;
  },
  setCurrentCulture (state, data) {
    state.siteSettings.currentCulture = data;
  },
  setLanguages (state, data) {
    state.languages = data;
  },
  setBrowserSupport (state, req) {
    const userAgent = req.headers['user-agent'];
    const msie = userAgent?.indexOf('MSIE '); // IE 10 or older
    const trident = userAgent?.indexOf('Trident/'); //IE 11

    if (typeof userAgent !== 'undefined' && (msie > 0 || trident > 0)) {
      // IE 11 or older set variable to true
      state.browserIsNotSupported = true;
    }
  },
  setUserMenu (state, data) {
    state.userMenu = data;
  },
};

export const actions = {
  async fetchSiteSettings ({ commit }, { req }) {
    const url = decodeURIComponent(req.url);
    try {
      const { data } = await this.$axios.get(
        '/api/settings',
        {
          params: { url }
        }
      );
      commit('setBrowserSupport', req);
      commit('setSiteSettings', data.result);
    } catch (error) {
      console.log(error)
    }
  },
  async fetchUserMenu ({ commit, getters }) {
    const culture = getters['getCurrentCulture'];
    const myAccountTemplate = getters['getSiteSettings']?.myAccountTemplate;
    const guid = myAccountTemplate?.meta?.id;

    const data = await this.$repositories.content.getChildren(culture, guid);

    commit('setUserMenu', data);
  }
};

export const getters = {
  getFooter (state) {
    return state.siteSettings.footer?.body;
  },
  getHeader (state) {
    return state.siteSettings.settings?.body.content;
  },
  getCdnUrl (state) {
    return state.siteSettings.settings?.body.content?.cdnUrl;
  },
  getPlaceholder (state) {
    let images = state.siteSettings.settings?.body?.content?.imagePlaceholder;
    // Make Kontainer image look like an Umbraco image
    return {
      url: images && images.length ? images[0].Url : null,
      name: '',
      focalPoint: '1'
    };
  },
  getSiteSettings (state) {
    return state.siteSettings.settings?.body.content;
  },
  getEmailSettings (state) {
    return state.siteSettings.settings?.body.email;
  },
  getLanguages (state) {
    return state.languages;
  },
  getSitePopup (state) {
    return state.siteSettings.settings?.body?.popup;
  },
  getCurrentCulture (state) {
    // Force to lowercase to avoid unnecessary redirects
    return state.siteSettings?.currentCulture?.toLowerCase();
  },
  getBrowserSupportStatus (state) {
    return state.browserIsNotSupported;
  }
};
