<template>
  <div class="m-promotion-card">
    <base-image
      v-if="content.imageUrl"
      :url="content.imageUrl"
      :width="250"
      :height="230"
      :alt-text="content.headline"
      class="m-promotion-card__image"
    />
    <h2 class="m-promotion-card__headline">
      {{ content.headline }}
    </h2>
    <p class="m-promotion-card__description">
      {{ content.text }}
    </p>
    <base-link
      v-if="content.linkUrl"
      :link="content.linkUrl"
      target="_blank"
      class="m-promotion-card__link"
    >
      {{ content.linkText }}
    </base-link>
  </div>
</template>


<script>
import BaseImage from '@/components/atoms/BaseImage';
import BaseLink from '@/components/atoms/BaseLink';

export default {
  name: 'PromotionCard',
  components: {
    BaseImage,
    BaseLink
  },
  props: {
    content: {
      type: Object,
      required: true
    }
  },

  computed: {

  }
}
</script>
