
export default async function (ctx) {
  /*
    Prepare the currency data for the store - will be in use when we activate addditional language layers
    - MRA

    if (process.server) {
      try {
        let currencies = await ctx.$repositories.currency.getCurrencies();
        let currencyCookie = ctx.$cookies.get('currency');

        let fallbackCurrencies = {
          'www.bronnum.dk': 'DKK',
          'www.bronnum.se': 'SEK',
          'www.bronnum.com': 'EUR'
        };
        let fallbackCurrency = fallbackCurrencies[process.env.origin] ? fallbackCurrencies[process.env.origin] : 'DKK';
        let selectedCurrency = currencyCookie && currencies.some(currency => currency.name === currencyCookie) ? currencyCookie : fallbackCurrency;

        ctx.store.commit('currency/setCurrencies', currencies);
        ctx.store.commit('currency/setCurrentCurrency', selectedCurrency);
      } catch (error) {
        console.error('Error fetching currencies', error);
      }
    }
  */
  return ctx;
}
