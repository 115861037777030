<template>
  <nav class="m-desktop-navigation">
    <div class="m-desktop-navigation__container d-flex flex-column">
      <div class="m-desktop-navigation__nav">
        <desktop-navigation-item
          v-if="navigationItems"
          :items="navigationItems"
          :open="open"
          :active-state="activeState"
        />
      </div>
    </div>
    <portal
      to="megamenu"
    >
      <div
        v-for="(item, i) in navigationItems"
        :key="i"
      >
        <mega-menu
          v-if="hasVisibleChildren(item)"
          :items="item.children"
          :visible="open === item.id && activeState"
        />
      </div>
    </portal>
  </nav>
</template>

<script>
import MegaMenu from '@/components/organisms/MegaMenu.vue';
import DesktopNavigationItem from '@/components/molecules/TheDesktopNavigationItem.vue';

export default {
  name: 'TheNavigation',
  components: {
    MegaMenu,
    DesktopNavigationItem,
  },
  computed: {
    navigationItems () {
      return this.$store.getters['navigation/getNavigationItems']?.filter(x => x && x?.link?.name && !x.hideInNavigation);
    },
    sliceBefore () {
      return Math.round(this.navigationItems.length / 2);
    },
    sliceAfter () {
      return this.navigationItems.length - Math.floor(this.navigationItems.length / 2);
    },
    activeState () {
       return this.$store.getters['navigation/getDesktopActiveState'];
    },
    open () {
      return this.$store.getters['navigation/getActiveId'];
    },
  },
  methods: {
    hasVisibleChildren (item) {
      return item.hasChildren && item.children.filter(x => !x.hideInNavigation).length > 0;
    },
  },
};
</script>
