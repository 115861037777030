<template>
  <button
    :aria-label="ariaLabel"
    class="m-wishlist-button"
    :class="{'-is-active': isProductOnWishList}"
    @click="toggleIsProductOnWishlist"
  >
    <slot name="icon" v-bind="{ wishListIcon }">
      <base-icon
        :icon="wishListIcon"
        class="m-wishlist-button__icon"
        :class="{'text-danger': isProductOnWishList}"
      />
    </slot>
  </button>
</template>

<script>
import BaseIcon from '@/components/atoms/BaseIcon.vue'
export default {

  name: 'WishlistButton',
  components: { BaseIcon },
  props: {
    /**
     * Product id
     */
    productId: {
      type: String,
      required: true
    },
  },
  computed: {
    ariaLabel () {
      return this.isProductOnWishList ? 'Remove from wishlist' : 'Add to wishlist';
    },
    favoriteList () {
      return this.$store.getters['user/user/getFavoriteList'];
    },
    isProductOnWishList () {
      return this.favoriteList.includes(this.productId);
    },
    wishListIcon () {
      return this.isProductOnWishList ? 'favorite' : 'favoriteOutlined';
    }
  },
  methods: {
    toggleIsProductOnWishlist () {
      if (this.isProductOnWishList) {
        this.$store.dispatch('user/user/removeProductFromFavoriteList', { productId: this.productId });
        return;
      }

      this.$store.dispatch('user/user/addProductToFavoriteList', { productId: this.productId });
    }
  }
}
</script>
