export default {
  computed: {
    user (){
      return this.$store.getters['user/user/getUser']
    },
    isLoggedIn () {
      return this.$store.getters['user/user/isLoggedIn'];
    }
  },
};
