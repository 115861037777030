const resource = '/shop-api/order'

export default (ctx, $axios) => ({
  byId (culture, orderGuid) {
    return $axios.$get(`${resource}`, {
      params: {
        culture,
        orderId: orderGuid
      }
    })
  },
  setProperty (orderId, propertyName, propertyValue) {
    return $axios.$get(`${resource}/setproperty`, {
      params: {
        orderId,
        propertyName,
        propertyValue
      }
    })
  },
  setOrderDiscount (amount) {
    return $axios.$post(`${resource}/discount/add?amount=${amount}`)
  }
})
