export const getKolliNumber = product => {
  let salesMultiplum = 1
  if (product.productAttributes){
    salesMultiplum = product.productAttributes.salesMultiplum
  }
  else {
    salesMultiplum = product.properties.find(property => property.name?.toLowerCase() === 'salesmultiplum')?.value;
  }
  
  const kolliNumber = parseInt(salesMultiplum);

  if (kolliNumber > 1) {
    return kolliNumber;
  }

  return 1;
};

export const getEnergyLabel = product => {
  const energyLabel = product.properties?.find((property) => property.name?.toLowerCase() === 'energimaerke')?.value;
  return energyLabel;
};
