<template>
  <div class="a-skeleton position-relative overflow-hidden" :style="aspectStyle" v-bind="$attrs">
    <div v-if="layout === 'image' || layout === ''" class="a-skeleton__loader d-flex align-items-center bg-light">
      <base-loader v-if="showLoader" />
    </div>

    <div v-else-if="layout === 'card'" class="a-skeleton__loader">
      <div class="bg-light w-100 h-75">
        <base-loader v-if="showLoader" />
      </div>
      <div class="mt-5 bg-light w-75 py-3" />
      <div class="mt-5 bg-light w-50 py-3" />
    </div>

    <div v-else-if="layout === 'row'" class="a-skeleton__loader d-flex">
      <div class="bg-light w-25 h-100">
        <base-loader v-if="showLoader" />
      </div>
      <div class="ml-5 w-75">
        <div class="mb-5 bg-light w-75 py-5" />
        <div class="mb-5 bg-light w-50 py-4" />
        <div class="mb-5 bg-light w-25 py-3" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseLoader from '@/components/atoms/BaseLoader.vue'
export default {
  name: 'Skeleton',
  components: {
    BaseLoader
  },
  props: {
    /**
     * Aspect ratio
     */
    aspectRatio: {
      type: Number,
      default: 0,
    },
    showLoader: {
      type: Boolean,
      default: false,
    },
    layout: {
      type: String,
      default: '',
    },
  },
  computed: {
    computedAspectRatio () {
      return Number(this.aspectRatio);
    },
    aspectStyle  () {
      return this.computedAspectRatio
        ? { paddingBottom: (1 / this.computedAspectRatio) * 100 + '%' }
        : undefined;
    }
  }
}
</script>
