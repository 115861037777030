<template>
  <button
    v-bind="$attrs"
    class="m-basket-button"
    :class="shippingIndicatorClass"
    v-on="$listeners"
  >
    <base-icon icon="bronnum-basket" />
    <span
      v-if="basket.orderSummary && basket.orderSummary.totalQuantity > 0"
      class="m-basket-button__icon-counter"
    >
      {{ basket.orderSummary.totalQuantity }}
    </span>
  </button>
</template>

<script>
import BaseIcon from '@/components/atoms/BaseIcon';
export default {
  name: 'BasketButton',
  components: {
    BaseIcon
  },
  computed: {
    basket () {
      return this.$store.getters['cart/basket/getBasket'];
    },
    shippingIndicatorClass () {
      const classes = {
        0: '-far',
        1: '-near',
        2: '-exceeded',
      }
      return classes[this.basket?.customProperties?.threshold] ?? '';
    }
  },
}
</script>
