export const state = () => ({
  products: []
});

export const mutations = {
  setProducts (state, data) {
    state.products = data;
  }
};

export const actions = {
  async fetchProductsByIds ({ commit }, props) {
    const data = await this.$repositories.products.byIds(props.culture, props.productIds)
    commit('setProducts', data);
    return data;
  }
};

export const getters = {
  getProducts (state) {
    return state.products;
  }
};
