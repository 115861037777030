import Vue from 'vue';

Vue.prototype.$isNotNullOrUndefined = value => {
  return typeof value !== 'undefined' && value;
};
Vue.prototype.$isNullOrUndefined = value => {
  return value === null || typeof value === 'undefined';
};

Vue.prototype.$firstOrDefault = value => {
  return Vue.prototype.$isNotNullOrUndefined(value) ? value[0] : null;
};

Vue.prototype.$getRandomString = len => {
  let charSet =  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var randomString = '';
  for (var i = 0; i < len; i++) {
      var randomPoz = Math.floor(Math.random() * charSet.length);
      randomString += charSet.substring(randomPoz,randomPoz+1);
  }
  return randomString;
}