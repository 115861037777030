<template>
  <div>
    <price
      v-if="isLoggedIn && prices"
      :price="prices"
      :is-product-on-discount="isProductOnDiscount"
    />
  </div>
</template>

<script>
import Price from '@/components/atoms/Price'
import LoginMixin from '@/mixins/loginMixin'
export default {
  name: 'PriceUser',
  components: {
    Price
  },
  mixins: [LoginMixin],
  props: {
    sku: {
      type: [String, Number],
      required: true
    },
    isProductOnDiscount: {
      type: Boolean,
      default: false
    },
    isLavpris: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      prices: null,
    }
  },
  computed: {
    currency () {
      return this.$store.getters['currency/getCurrentCurrency'];
    },
  },
  watch: {
    sku () {
      this.getPrice(); // If the filter is used we need to update the price
    },
    currency () {
      this.getPrice();
    }
  },
  created () {
    this.getPrice();
  },
  methods: {
    async getPrice () {
      if (this.isLoggedIn) {
        this.prices = await this.$repositories.prices.get(this.sku, this.currency, this.user.accountProperties.customerNo);
        this.$emit('price-updated', this.prices);
      }
    }
  }
};
</script>
