const resource = '/shop-api/stocklevel'
const backInStockResource = '/shop-api/backinstock'

export default (ctx, $axios) => ({
  getStock (sku, culture) {
    return $axios.$get(`${resource}/${sku}/${culture}`);
  },

  getStockNotification (email, sku) {
    return $axios.$get(`${backInStockResource}/register`, {
      params: {
        email: email,
        sku: sku
      }
    })
  },
})
