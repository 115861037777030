export const state = () => ({
  products: [],
  isLoading: false
});

export const mutations = {
  setProducts (state, data) {
    state.products = data;
  },
  setLoading (state, data) {
    state.isLoading = data;
  }
}

export const actions = {
  async fetchSearchResults ({ commit }, props) {
    try {
      commit('setLoading', true)
      let response = await this.$repositories.products.byQuery(
        props.query,
        props.culture,
        props.itemsPerPage,
        props.currentPage,
        props.facets,
        props.orderBy,
        props.order,
        props.filters,
        props.startFromFirstPage
      );
      response.items = await this.$kontainer.getPackshots( response?.items );
      commit('setLoading', false)
      commit('setProducts', response)
      return response;
    } catch (error) {
      console.log(error)
    }
    return;
  },
};

export const getters = {
  getProducts (state) {
    return state.products;
  },
  isLoading (state) {
    return state.isLoading;
  }
}
