<template>
  <div
    class="a-input"
    :class="{
      '-has-text': (inputType === 'date') ? true : fieldHasText,
      '-invalid': fieldHasErrror
    }"
  >
    <label v-if="label" class="a-input__label" :for="fieldId ? fieldId : name">
      <!-- @slot Custom input label -->
      <slot name="label" v-bind="{ label }">{{ label }}</slot>
    </label>
    <span v-if="showCharacterLimit" class="a-input__limit small">
      {{ $isNotNullOrUndefined(value) ? value.length : '0' }}/{{ maxInputLength }}
    </span>
    <div class="a-input__wrapper">
      <input
        :id="fieldId ? fieldId : name"
        v-bind="$attrs"
        :value="( Array.isArray(value) ? $firstOrDefault(value) : value)"
        :disabled="disabled"
        :name="name"
        :type="inputType"
        :aria-label="ariaLabel"
        :maxlength="maxInputLength"
        v-on="listeners"
      >
      
      <span v-if="showFeedbackIcon" class="a-input__icon">
        <base-icon v-if="!fieldHasErrror && !!value" class="a-input__icon--success" icon="checkmarkOutlined" />
        <base-icon v-else-if="fieldHasErrror" class="a-input__icon--error" icon="attention" />
      </span>
    </div>
    <div v-if="fieldHasErrror" class="a-input__error-message">
      <transition name="fade">
        <span v-if="errorMessage && errorMessage !== ''">
          <!-- @slot Custom error message of form input -->
          <slot name="error-message" v-bind="{ errorMessage }">
            {{ errorMessage }}
          </slot>
        </span>
        <span v-else>
          {{ customErrorMessage }}
        </span>
      </transition>
    </div>
  </div>
</template>
<script>
import BaseIcon from './BaseIcon.vue'

export default {
  name: 'BaseInput',
  components: {
    BaseIcon
  },
  props: {
    fieldId: {
      type: String,
      default: ''
    },
    /**
     * Current input value (`v-model`)
     */
    value: {
      type: [String, Number, Array],
      default: null
    },
    /**
     * Form input label
     */
    label: {
      type: String,
      default: null
    },
    /**
     * Form input name
     */
    name: {
      type: String,
      default: null
    },
    /**
     * Form input type
     */
    type: {
      type: String,
      default: 'text'
    },
    /**
     * Error message value of form input. It will be appeared if `valid` is `true`.
     */
    errorMessage: {
      type: String,
      default: null
    },
    /**
     * Native input disabled attribute
     */
    disabled: {
      type: Boolean,
      default: false,
      description: 'Native input disabled attribute'
    },
    /**
     * Form input aria-label
     */
    ariaLabel: {
      type: String,
      default: null
    },
    /**
     * Show feedback icon
     */
    showFeedbackIcon: {
      type: Boolean,
      default: false
    },
    customErrorMessages: {
      type: Object,
      default: () => {}
    },
    maxInputLength: {
      type: Number,
      default: 524288
    }
  },
  data () {
    return {
      inputType: ''
    };
  },
  computed: {
    showCharacterLimit () {
      return this.maxInputLength !== 524288 && !this.disabled;
    },
    fieldHasText () {
      let val = Array.isArray(this.value) ? this.$firstOrDefault(this.value) : this.$isNotNullOrUndefined(this.value) ? this.value : ''

      return (!!val || val.length > 0 )
    },
    listeners () {
      return {
        ...this.$listeners,
        input: event => this.$emit('input', event.target.value)
      };
    },
    fieldHasErrror () {
      return typeof (this.errors) !== 'undefined' && this.errors.items.find(x => x.field === this.name) != null;
    },
    customErrorMessage () {
      const field = this.errors.items.find(x => x.field === this.name);
      return this.customErrorMessages && this.customErrorMessages[field.rule] ? this.customErrorMessages[field.rule] : field.msg;
    }
  },
  watch: {
    type: {
      immediate: true,
      handler: function (value) {
        this.inputType = value;
      }
    }
  }
};
</script>
