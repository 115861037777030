export const state = () => ({
  basket: {},
  images: [],
  isLoading: false
});

export const mutations = {
  setBasket (state, data) {
    state.basket = data;
  },
  setImages (state, data) {
    state.images = data;
  },
  setLoadingState (state, data) {
    state.isLoading = data;
  }
};

export const actions = {
  async fetchBasket ({ commit }, props) {
    commit('setLoadingState', true);
    try {
      const data = await this.$repositories.basket.get(props.culture, props.userId);
      commit('setBasket', data);
      const images = data?.orderLines ? await this.$kontainer.getPackshots(data?.orderLines, true) : [];
      commit('setImages', images);
    } catch {
      // Die silently
    }
    commit('setLoadingState', false);
  },
  async addToBasket ({ commit }, props) {
    commit('setLoadingState', true);
    console.log(props)
    const data = await this.$repositories.basket.add(props.culture, props.sku, props.currency, props.quantity, props.properties);
    commit('setBasket', data);
    commit('setLoadingState', false);
    const images = await this.$kontainer.getPackshots(data?.orderLines, true);
    commit('setImages', images);
    return data;
  },
  async updateOrderline ({ commit }, props) {
    commit('setLoadingState', true);
    const data = await this.$repositories.basket.updateOrderline(props.culture, props.orderlineId, props.newQuantity);
    commit('setBasket', data);
    commit('setLoadingState', false);

  },
  async setBillingAddress ({ commit }, props) {
    commit('setLoadingState', true);
    let firstName = '';
    let lastName = '';

    if (props.billingInformation.fullName.includes(' ')) {
      firstName = props.billingInformation.fullName.substring(0, props.billingInformation.fullName.lastIndexOf(' '));
      lastName = props.billingInformation.fullName.substring(props.billingInformation.fullName.lastIndexOf(' ') + 1, props.billingInformation.fullName.length);
    } else {
      firstName = props.billingInformation.fullName;
    }

    const billingInformation = {
      firstName,
      lastName,
      streetName: props.billingInformation.address,
      streetName2: '',
      companyName: props.billingInformation.companyName ?? '',
      zipCode: props.billingInformation.zipCode,
      cityName: props.billingInformation.city,
      countryCode: 'DK',
      telephone: props.billingInformation.phoneNumber,
      email: props.billingInformation.email
      // TODO: Add CVR number whenever available
    }

    const data = await this.$repositories.billing.set(props.culture, billingInformation);
    commit('setBasket', data);
    commit('setLoadingState', false);
    return data;
  },
};

export const getters = {
  getBasket (state) {
    return state.basket;
  },
  getImages (state) {
    return state.images;
  },
  isLoading (state) {
    return state.isLoading;
  },
  isBasketEmpty (state) {
    return state.basket?.orderLines?.length === 0;
  }
};
