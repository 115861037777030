export const strict = false;

export const state = () => ({});

export const mutations = {};

export const actions = {
  async nuxtServerInit ({ dispatch }, { req }) {
    const gethNavigation = dispatch('navigation/fetchNavigation', { root: true, req: req });
    const getShopNavigation = dispatch('navigation/fetchShopNavigation', { root: true, req: req });
    const getSettings = dispatch('site/fetchSiteSettings', { root: true, req: req });

    try {
      await Promise.all([gethNavigation, getSettings, getShopNavigation]);
    } catch (error) {
      console.error('Error on fetching navigation and settings', error);
    }
  }
};
