<template>
  <base-link v-if="$isNotNullOrUndefined(logo)" class="logo" :link="rootUrl">
    <base-image
      :url="logo.url"
      :width="width"
      :height="height"
      image-class="logo__image"
      class="lazypreload"
      :preload="true"
      :responsive="false"
    />
  </base-link>
</template>

<script>
import BaseLink from '@/components/atoms/BaseLink';
import BaseImage from '@/components/atoms/BaseImage';

export default {
  name: 'TheLogo',
  components: {
    BaseLink,
    BaseImage
  },
  props: {
    width: {
      type: Number,
      default: 164
    },
    height: {
      type: Number,
      default: 27
    }
  },
  computed: {
    logo () {
      return this.$isNotNullOrUndefined(this.$store.getters['site/getSiteSettings'])
        ? this.$store.getters['site/getSiteSettings']?.siteLogo
        : null;
    },
    rootUrl () {
      let navigation = this.$store.getters['navigation/getContentNavigation'];
      return navigation && navigation.link ? navigation.link.url : null;
    }
  },
};
</script>
