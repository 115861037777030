const resource = '/shop-api/payment'

export default (ctx, $axios) => ({
  getMethods () {
    return $axios.$get(`${resource}/methods`, {
      params: {
        countryId: '6'
      }
    });
  },
  setPaymentMethod (culture, paymentMethod) {
    return $axios.$post(`${resource}/setpaymentmethod`, null, {
      params: {
        culture,
        paymentMethodId: paymentMethod
      }
    });
  },
  getPaymentPageUrl () {
    return $axios.$get(`${resource}/getpaymentpageurl`);
  },
})
