export const getSpots = async ({parentId, culture, recursive, pageTypes, sortOrder, orderByDescending}) => {
  if (!process.client) return;

  let endpoint = recursive ? 'getchildrenrecursive' : 'getchildren';
  let promises = pageTypes.map(async pageType => {
    let result = await $nuxt.$axios.get(`/api/content/${endpoint}/${parentId}/${culture}/${pageType}`,
    {
      params: {
        sortOrder,
        orderByDescending,
      },
      validateStatus: false
    });

    let items = result?.data?.map(getMappedChildren);
    return items;
  });
  // Merge arrays
  let spots = [].concat(...(await Promise.all(promises)));

  return spots;
}

export const getSearchSpots = (items) => {
  return items.map(getMappedChildren);
}

export const getSpotsByTemplate = (contentResults, templateTypes) => {
  // We cannot manipulate the computed object directly, so we'll make a copy
  let result = { ...contentResults };
  // Only list specified types
  result.items = result?.items?.filter(item => {
    return templateTypes.includes(item.ncContentTypeAlias);
  });
  return result;
}


const getMappedChildren = (child) => {
  let redirectUrl = child?.body?.advanced?.umbracoRedirect?.url;
  return {
    image: child?.body?.spotContent?.spotPage_Image,
    imageAltText: child?.body?.spotContent?.spotPage_imageAltText,
    tags: child?.body?.spotContent?.spotPage_tags,
    headline: child?.body?.spotContent?.spotPage_headline,
    subline: child?.body?.spotContent?.spotPage_subline,
    text: child?.body?.spotContent?.spotPage_text,
    link: {
      url: redirectUrl ? redirectUrl : child?.url,
      name: child?.body?.spotContent?.spotPage_linkText
    },
    key: child?.meta.id,
    ncContentTypeAlias: child?.meta?.template,
    firstPublishDate: child?.meta?.createDate,
    headlineSecondary: child?.body?.spotContent?.spotPage_headlineSecondary,
    sublineSecondary: child?.body?.spotContent?.spotPage_sublineSecondary,
    imageSecondary: child?.body?.spotContent?.spotPage_imageSecondary,
    textSecondary: child?.body?.spotContent?.spotPage_textSecondary
  };
}
