<template>
  <div class="o-sidebar" :class="[staticClass, className]">
    <overlay subtle :visible="visibleOverlay" @click="close" />
    <transition name="slide-right">
      <aside v-show="visible" class="o-sidebar__aside" :class="[scrolled ? '-header-scrolled' : '']">
        <slot name="bar">
          <div class="o-sidebar__bar d-flex align-items-center justify-content-between px-4 pt-3 px-md-8 pt-md-8">
            <p class="o-sidebar__title mb-0">
              {{ title }}
            </p>
            <base-button class="o-sidebar__close -primary -no-outline -rounded" @click="close">
              <base-icon icon="close" class="o-sidebar__close-icon" />
            </base-button>
          </div>
        </slot>
        <div ref="content" class="o-sidebar__content px-4 px-md-8 pt-7">
          <!--@slot Use this slot to add SfSidebar content.-->
          <slot />
        </div>
        <!--@slot Use this slot to place content to sticky bottom.-->
        <div v-if="hasBottom" class="o-sidebar__bottom">
          <slot name="content-bottom" />
        </div>
      </aside>
    </transition>
  </div>
</template>
<script>
import BaseButton from '@/components/atoms/BaseButton.vue'
import BaseIcon from '@/components/atoms/BaseIcon.vue'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import Overlay from '@/components/atoms/Overlay';

export default {
  name: 'Sidebar',
  components: {
    Overlay,
    BaseIcon,
    BaseButton
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    headingLevel: {
      type: Number,
      default: 3,
    },
    button: {
      type: Boolean,
      default: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    overlay: {
      type: Boolean,
      default: true,
    },
    bodyScroll: {
      type: Boolean,
      default: false,
    },
    scrolled: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      position: 'left',
      staticClass: null,
      className: null,
    };
  },
  computed: {
    visibleOverlay () {
      return this.visible && this.overlay;
    },
    transitionName () {
      return 'slide-' + this.position;
    },
    hasBottom () {
      return Object.prototype.hasOwnProperty.call(this.$slots, 'content-bottom');
    },
  },
  watch: {
    visible: {
      handler (value) {
        if (typeof window === 'undefined' || typeof document === 'undefined')
          return;
        if (value) {
          document.addEventListener('keydown', this.keydownHandler);
        } else {
          document.removeEventListener('keydown', this.keydownHandler);
        }

        this.bodyScrollHandler();
      },
      immediate: true,
    },
    bodyScroll: {
      handler () {
        this.bodyScrollHandler();
      },
      immediate: true,
    },
    $route () {
      if (this.visible) {
        this.close();
      }
    },
  },
  mounted () {
    this.classHandler();
  },
  updated () {
    this.classHandler();
  },
  methods: {
    close () {
      this.$emit('close');
    },
    keydownHandler (e) {
      if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
        this.close();
      }
    },
    classHandler () {
      let update = false;
      if (this.staticClass !== this.$vnode.data.staticClass) {
        this.staticClass = this.$vnode.data.staticClass;
        update = true;
      }
      if (this.className !== this.$vnode.data.class) {
        this.className = this.$vnode.data.class;
        update = true;
      }
      if (update) {
        this.position =
          [this.staticClass, this.className].toString().search('--right') > -1
            ? 'right'
            : 'left';
      }
    },
    bodyScrollHandler () {
      if (!process.client) {
        return;
      }

      if (!this.visible) {
        clearAllBodyScrollLocks();
        return;
      }

      if (!this.bodyScroll) {
        this.$nextTick(() => {
          disableBodyScroll(this.$refs.content);
        });
      } else {
        clearAllBodyScrollLocks();
      }
    }
  },
};
</script>
