const resource = '/shop-api/shipping';

export default (ctx, $axios) => ({
  getMethods (culture) {
    return $axios.$get(`${resource}/methods`, {
      params: {
        countryId: '6',
        culture,
      }
    })
  },
  setMethod (culture, shippingMethodId) {
    return $axios.$post(`${resource}/setshippingmethod`, null, {
      params: {
        culture,
        shippingMethodId
      }
    })
  },
  setAddress (culture, shippingAddress) {
    return $axios.$post(`${resource}/addaddress`, shippingAddress, {
      params: {
        culture
      }
    });
  }
})
