import { getEnergyLabel } from '@/utils/ShopCommerce/product-properties';

export default {
  data () {
    return {
      downloads: []
    }
  },
  async mounted () {
    this.downloads = await this.getAssets(this.product?.sku);
  },
  computed: {
    energyLabel () {
      return getEnergyLabel(this.product);
    },
    energyLabelDownload () {
      return this.downloads?.find(download => download.name === 'Energimærkning') ?? {};
    },
  },
  methods: {
    async getAssets (sku) {
      let assets = await this.$repositories.assets.bySkus([sku]);
      return assets[0]?.data?.files;
    }
  }
};
