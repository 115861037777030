
<template>
  <div>
    <browse-happy-alert v-if="browserIsNotSupported" />
    <the-header />
    <nuxt />
    <the-footer />
    <client-only>
      <portal-target name="modal">
        <!--
        Placeholder for modal content
        -->
      </portal-target>
    </client-only>
  </div>
</template>

<script>
import BrowseHappyAlert from '@/components/organisms/BrowseHappyAlert.vue';
import TheHeader from '@/components/organisms/TheHeader.vue';
import TheFooter from '@/components/organisms/TheFooter.vue';
import { trackPageView, gtmDOM, gtmLoad, gtmInitConsent } from '@/utils/enhancedTracking';

export default {
  name: 'Default',
  components: {
    TheHeader,
    TheFooter,
    BrowseHappyAlert
  },
  computed: {
    browserIsNotSupported () {   
      return this.$store.getters['site/getBrowserSupportStatus'];
    },
    user () {
      return this.$store.getters['user/user/getUser'];
    },
    isLoggedIn () {
      return this.$store.getters['user/user/isLoggedIn'];
    },
  },
  watch: {
    $route () {
      gtmDOM();
      gtmLoad();
      gtmInitConsent();
      this.trackPageView();
    },
  },
  mounted () {
    this.trackPageView();
  },
  methods: {
    trackPageView () {
      trackPageView({user: this.user, isLoggedIn: this.isLoggedIn});
    }
  },
};
</script>
