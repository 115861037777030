
export default async function ( ctx ) {
  let data = {};
  let statusCode = 200;
  const url = decodeURIComponent( ctx.route.path );
  const host = process.server ? ctx.req.headers.host : window.location.host;

  try {
    let result = await ctx.$repositories.content.byUrl(url);
    data = result.data;
    statusCode = result.status;
  } catch ( error ) {
    console.error( error );
    return ctx.error( {
      statusCode: 500,
      message: 'Something went wrong'
    } );
  }

  // Handle redirects
  if (statusCode >= 300 && statusCode < 400) {
    let redirectUrl = data.result?.url;
    if (typeof redirectUrl !== 'undefined' && redirectUrl.length) {
      ctx.redirect( statusCode, redirectUrl );
    } else {
      const redirect = data.result?.redirectUrl || '';
      ctx.redirect( statusCode, redirect );
    }
    return ctx;
  }

  // 403 means that the content is restricted
  if (statusCode === 403) {
    let hasUser = data.result?.accessRestrictions?.isLoggedIn;
    let redirectUrl = '/';

    if(hasUser) {
      redirectUrl = data.result?.accessRestrictions?.noAccessPage;
    } else {
      redirectUrl = data.result?.accessRestrictions?.loginPage;
    }

    ctx.redirect(302, redirectUrl);
    return ctx;
  }

  if (statusCode >= 400) {
    ctx.error( { statusCode: statusCode });
  }

  const page = data?.result;
  const spotContent = page?.body?.spotContent;
  const navigation = page?.navigation;
  const content = page?.body?.content;
  const social = page?.body?.social;
  const meta = page?.meta;
  const seo = page?.body?.sEO;

  // Set current culture from content repsonse
  const currentCulture = data.context && data.context.culture ? data.context.culture : 'da-DK';

  if ( ctx.store.getters[ 'site/getCurrentCulture' ] !== currentCulture ) {
    ctx.store.commit( 'site/setCurrentCulture', currentCulture );
  }

  const payload = {
    seo,
    spotContent,
    navigation,
    content,
    meta,
    social,
    host
  };

  ctx.store.commit( 'content/content/setContent', payload );
  ctx.store.commit( 'content/content/setPageUid', meta?.id );
  ctx.store.commit( 'content/content/setPageId', page?.nodeid );
  ctx.payload = payload;

  return ctx;
}
