<template>
  <div class="m-accordion" :class="{'-open': open }">
    <div v-if="title" class="m-accordion__title d-flex justify-content-between align-items-center h6 mb-3" @click="toggle(title)">
      <template v-if="$isNotNullOrUndefined(displayTitle)">
        {{ displayTitle }}
      </template><template v-else>
        {{ title }}
      </template>
      <base-icon
        v-if="showChevron"
        icon="chevron-down"
        class="m-accordion__icon"
        :class="{ '-open': open, '-hide-icon': !hideContentDesktop }"
      />
    </div>
    <transition v-if="title && animated" name="fade">
      <div class="m-accordion__content" :class="{'-desktop-show': !hideContentDesktop}">
        <slot />
      </div>
    </transition>
    <div v-else-if="title && !animated">
      <div class="m-accordion__content" :class="{'-desktop-show': !hideContentDesktop}">
        >
        <slot />
      </div>
    </div>
    <div v-else class="m-accordion__content test" :class="{'-desktop-show': !hideContentDesktop}">
      <slot />
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/atoms/BaseIcon';
export default {
  name: 'Accordion',
  components: {
    BaseIcon
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    displayTitle: {
      type: String,
      default: ''
    },
    showChevron: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    animated: {
      type: Boolean,
      default: true
    },
    hideContentDesktop: {
      type: Boolean,
      default: false
    },
    isFilters: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    open () {
      return this.$parent.open.includes(this.title);
    }
  },
  methods: {
    toggle (payload) {
      if(this.isFilter && !this.open) {
        this.$parent.gtmCategories(payload);
      }
      this.$parent.toggle(payload);
    },
  }
};
</script>
