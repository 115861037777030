const resourceByUrl = '/shop-api/categories';
const resourceByGuid = '/shop-api/category';

export default (ctx, $axios) => ({
  byParentId (parentId, culture) {
    return $axios.$get(`${resourceByUrl}/byparentid/${parentId}/${culture}`)
  },
  byGuid (guid, culture) {
    return $axios.$get(`${resourceByGuid}/${guid}/${culture}`)
  },
  byGuids (guids, culture) {
    let categoryIds = guids?.join(',');
    return $axios.$get(`${resourceByUrl}/${culture}`, {
      params: {
        categoryIds
      }
    })
  },
  getRootByGuid (guid, culture) {
    return $axios.$get(`${resourceByUrl}/getrootcategories/${culture}/${guid}`)
  }
})
