<template>
  <div v-if="browseHappyText" class="position-fixed fixed-bottom bg-warning py-10 text-center shadow">
    <table class="m-auto">
      <tbody>
        <tr>
          <td class="align-middle pb-4">
            <base-icon icon="alert-circled" class="display-1" />
          </td>
          <td class="align-middle lead font-weight-normal pl-4" v-html="browseHappyText" />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import BaseIcon from '@/components/atoms/BaseIcon.vue';
export default {
  name: 'BrowseHappyAlert',
  components: {
    BaseIcon
  },
  computed: {
    browseHappyText () {
      return this.$isNotNullOrUndefined(this.$store.getters['site/getSiteSettings']) && this.$store.getters['site/getSiteSettings'].browseHappyText;
    }
  }
}
</script>
