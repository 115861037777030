export const state = () => ({
  paymentOptions: [],
  shippingMethods: [],
  orderNumber: null,
});

export const mutations = {
  setShippingMethods (state, data) {
    state.shippingMethods = data;
  },
  setPaymentOptions (state, data) {
    state.paymentOptions = data;
  },
  setOrderNumber (state, data) {
    state.orderNumber = data;
  },
};

export const actions = {
  async fetchShippingMethods ({ commit }, props) {
    const data = await this.$repositories.shipping.getMethods(props.culture);
    commit('setShippingMethods', data);
  },
  async setShippingMethod ({ commit }, props) {
    const data = await this.$repositories.shipping.setMethod(props.culture, props.shippingMethodId);
    commit('cart/basket/setBasket', data, { root: true });
  },
  async setShippingAddress ({ commit }, props) {
    let firstName = '';
    let lastName = '';

    // handler for accountShipping information
    if (props.shippingAddress.fullName.includes('{split}')) {
      firstName = props.shippingAddress.fullName.split('{split}')[0];
      lastName = props.shippingAddress.fullName.split('{split}')[1];
    }
    // handler for billingShipping information
    else if (props.shippingAddress.fullName.includes(' ')) {
      firstName = props.shippingAddress.fullName.substring(0, props.shippingAddress.fullName.lastIndexOf(' '));
      lastName = props.shippingAddress.fullName.substring(props.shippingAddress.fullName.lastIndexOf(' ') + 1, props.shippingAddress.fullName.length);
    } else {
      firstName = props.shippingAddress.fullName;
    }

    const shippingAddress = {
      firstName,
      lastName,
      streetName: props.shippingAddress.address,
      streetName2: '',
      companyName: props.shippingAddress.companyName ?? '',
      zipCode: props.shippingAddress.zipCode,
      cityName: props.shippingAddress.city,
      countryCode: 'DK',
      telephone: props.shippingAddress.phoneNumber,
      email: props.shippingAddress.email
    }

    const data = await this.$repositories.shipping.setAddress(props.culture, shippingAddress);
    commit('cart/basket/setBasket', data, { root: true });
    return data;
  },
  async fetchPaymentOptions ({ commit }) {
    const data = await this.$repositories.payment.getMethods();
    commit('setPaymentOptions', data)
    return data;
  },
  async setPaymentMethod (context, props) {
    const data = await this.$repositories.payment.setPaymentMethod(props.culture, props.paymentMethod);
    return data;
  },
  async fetchPaymentUrl () {
    const data = await this.$repositories.payment.getPaymentPageUrl();
    return data;
  },
};

export const getters = {
  getShippingMethods (state) {
    return state.shippingMethods;
  },
  getPaymentOptions (state) {
    return state.paymentOptions
  },
  getOrderNumber (state) {
    return state.orderNumber;
  }
};
