<template>
  <transition :name="transition">
    <div
      v-if="visible"
      ref="overlay"
      class="a-overlay"
      :class="[staticClass, className, subtle ? '-subtle' : '']"
      @click="$emit('click')"
    />
  </transition>
</template>
<script>
export default {
  name: 'Overlay',
  props: {
    /**
     * Transition effect to apply when overlay visibility is changed
     */
    transition: {
      type: String,
      default: 'fade'
    },
    /**
     * Visibility state
     */
    visible: {
      type: Boolean,
      default: false
    },
    subtle: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    staticClass () {
      return this.$vnode.data.staticClass;
    },
    className () {
      return this.$vnode.data.class;
    }
  }
};
</script>
