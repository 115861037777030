
<template>
  <div class="o-orderline-compact mb-4 w-100 position-relative">
    <div class="d-flex o-orderline-compact__wrapper w-100">
      <div class="o-orderline-compact__image-wrapper pr-5">
        <base-link :link="product.productSlug">
          <base-image
            v-if="image"
            class="o-orderline-compact__image"
            :url="image.url"
            :alt-text="product.name"
            mode="pad"
            :width="131"
            :height="131"
          />
          <base-image
            v-else
            class="o-orderline-compact__image"
            :url="placeholder.url"
            :alt-text="product.name"
            :width="131"
            :height="131"
          />
        </base-link>
        <slot v-if="showBadge && product.badges" name="badge">
          <badge
            v-for="(index) in showBadgeCount"
            :key="`badge_${index}`"
            class="o-orderline-compact__badge"
            :class="{'bg-primary color-white' : product.badges[index - 1] === 'Tilbud', 'bg-sand color-grey' : product.badges[index - 1] !== 'Tilbud'}"
          >
            {{ product.badges[index-1] }}
          </badge>
        </slot>
        <!-- <badge
          v-if="$isNotNullOrUndefined(priceSpecial) && (price < priceSpecial)"
          class="o-orderline-compact__badge"
          :class="'bg-' + badgeColor"
        >
          {{ $t("offer", "Tilbud") }}
        </badge> -->
      </div>
      <div class="d-flex flex-column justify-content-start flex-grow-1">
        <div class="d-flex justify-content-between align-items-start">
          <div class="w-100">
            <slot name="info">
              <base-link
                class="o-orderline-compact__headline mb-0 font-weight-bold text-body text-decoration-none"
                :link="product.productSlug"
              >
                {{ product.name }}
              </base-link>
            </slot>

            <div class="text-xsmall text-muted">
              <span
                v-if="$isNotNullOrUndefined(product.sku)"
                class="mr-2"
              >{{ $t("productId", "Varenr.") }} {{ product.sku }}</span>
              <template
                v-if="$isNotNullOrUndefined(product) && $isNotNullOrUndefined(product.model)"
              >
                <span>//</span>
                <span class="ml-2">
                  {{ $t("productModel", "Model") }} {{ product.model }}
                </span>
              </template>
            </div>
          </div>
          <div class="o-orderline-compact__icons">
            <slot name="icon">
              <button class="o-orderline-compact__icon mr-3" @click="onEdit">
                <base-icon icon="edit" />
              </button>
            </slot>
            <slot name="icon">
              <button
                class="o-orderline-compact__icon"
                @click="onRemoveFromCart"
              >
                <base-icon icon="delete" />
              </button>
            </slot>
          </div>
        </div>
        <slot name="actions">
          <div
            class="d-flex flex-column flex-md-row align-items-end justify-content-md-between align-items-md-center mt-2"
          >
            <slot name="quantity">
              <quantity-input
                v-if="showQuantityInput"
                :id="product.id"
                class="align-self-start mt-3 mb-2 mt-md-0 -small"
                :min-quantity="kolliNumber"
                :kolli="kolliNumber"
                :quantity="product.quantity"
                @quantity-change="debounceOnQuantityChange"
              />
              <p v-else class="text-xsmall text-muted mb-0">
                {{ $t("amount", "Antal") }}: {{ product.quantity }} {{ $t("PCS", "stk") }}
              </p>
            </slot>
          </div>
          <div
            class="d-flex flex-column flex-md-row align-items-end justify-content-md-between align-items-md-center mt-3 flex-wrap"
          >
            <slot name="priceTotal">
              <div v-if="product.orderLineSummary.totalPriceExVat > 0" class="o-orderline-compact__total">
                <div class="o-orderline-compact__total-price">
                  {{ product.orderLineSummary.totalPriceExVatFormatted }}
                </div>
                <div
                  v-if="product.orderLineSummary.totalPriceExVat < product.orderLineSummary.totalPriceExVatBeforeDiscount"
                  class="o-orderline-compact__total-discount-price"
                >
                  {{ product.orderLineSummary.totalPriceExVatBeforeDiscountFormatted }}
                </div>
              </div>
            </slot>
            <slot name="price">
              <price
                v-if="product.price"
                :price="product.price"
                :is-product-on-discount="isProductOnDiscount"
                is-horizontal
                hide-regular-price
                class-name="-basket"
              />
            </slot>
          </div>
          <slot name="text">
            <span
              v-if="isOrderingItem"
              class="o-orderline-compact__bestillingsvare text-xsmall text-muted mt-2"
            >
              {{ $t("orderingItem", "Bestillingsvare") }}
            </span>
            <span
              v-if="isRestsalgItem"
              class="o-orderline-compact__restsalgsvare text-xsmall text-muted mt-2"
            >
              {{ $t("orderingItem", "Restsalgsvare") }}
            </span>
            <span v-if="isOutOfStock" class="text-xsmall text-muted mt-2">
              {{ $t("productShippedWhenInStockAgain") }}
            </span>
          </slot>
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
import BaseIcon from '@/components/atoms/BaseIcon'
import QuantityInput from '@/components/molecules/QuantityInput.vue'
import Price from '@/components/atoms/Price'
import BaseImage from '@/components/atoms/BaseImage'
import BaseLink from '@/components/atoms/BaseLink'
import { trackRemoveFromCart, trackAddToCart } from '@/utils/enhancedEcomTracking';
import debounce from 'debounce';
import { getKolliNumber } from '@/utils/ShopCommerce/product-properties';
import LoginMixin from '@/mixins/loginMixin';

export default {
  name: 'OrderlineCompact',
  components: {
    BaseLink,
    BaseImage,
    Price,
    QuantityInput,
    BaseIcon
  },
  mixins: [LoginMixin],
  props: {
        /**
     * Product image
     * It should be an url of the product
     */
    product: {
      type: Object,
      default () {
        return {};
      },
      required: true
    },
    /**
     * Badge color
     * It can be according to our standard colors, or legitimate CSS color such as `#fff`, `rgb(255,255,255)`), and `lightgray` or nothing.
     * Standard colors: `primary`, `secondary`, `white`, `black`, `accent`, `green-primary`, `green-secondary`, `gray-primary`, `gray-secondary`, `light-primary`, `light-secondary`, `pink-primary`, `pink-secondary`, `yellow-primary`, `yellow-secondary`, `blue-primary`, `blue-secondary`.
     */
    badgeColor: {
      type: String,
      default: '',
    },
    /**
     * Status of showing badge
     */
    showBadge: {
      type: Boolean,
      default: true,
    },
    /**
     * Show large version
     */
    isLarge: {
      type: Boolean,
      default: false,
    },
    /**
     * Show variant picker
     */
    showVariantPicker: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      showQuantityInput: false,
      isProductOnDiscount: false,
    };
  },
  computed: {
    currentCulture () {
      return this.$store.getters['site/getCurrentCulture'];
    },
    kolliNumber () {
      return getKolliNumber(this.product);
    },
    image () {
      return this.$store.getters['cart/basket/getImages']?.find(image => image?.sku === this.product?.sku);
    },
    placeholder () {
      return this.$store.getters['site/getPlaceholder'];
    },
    currency () {
      return this.$store.getters['currency/getCurrentCurrency'];
    },
    isOrderingItem () {
      return this.product?.customProperties?.orderingItem === 'True';
    },
    isRestsalgItem () {
      return this.product?.customProperties?.restsalgItem === 'True';
    },
    isOutOfStock () {
      return this.product?.customProperties?.outOfStock === 'True';
    },
    getLoginPageLink () {
      return this.$store.getters['site/getSiteSettings']?.loginLink?.url;
    }
  },
  
  created () {
    this.isProductOnDiscount = this.isPriceOnDiscount(this.product.price)
  },
  methods: {
    debounceOnQuantityChange: debounce(async function (payload) {
      this.updateOrderline(payload);

      if (payload.quantity > this.product.quantity) {
        trackAddToCart(this.product, payload.quantity, this.currency);
      } else if (payload.quantity < this.product.quantity) {
        trackRemoveFromCart(this.product, payload.quantity);
      }
    }, 300),
    onRemoveFromCart () {
      trackRemoveFromCart(this.product, 0);
      const payload = {
        id: this.product?.id,
        quantity: 0,
      }
      this.updateOrderline(payload);
    },
    onEdit () {
      this.showQuantityInput = !this.showQuantityInput;
    },
    async updateOrderline (payload) {
      if (this.isLoggedIn) {
        try {
          await this.$repositories.authentication.isLoggedIn(), Promise.resolve();
          this.onUpdateOrderline(payload);
        } catch {
          this.$store.dispatch('user/user/setUser', '');
          if(this.intervalId) {
            this.$router.push(this.getLoginPageLink);
          }
        }
      } else {
        this.onUpdateOrderline(payload);
      }
    },

    onUpdateOrderline (payload) {
      this.$store.dispatch('cart/basket/updateOrderline', {
          culture: this.currentCulture,
          orderlineId: payload.id,
          newQuantity: payload.quantity,
        });
    },

    isPriceOnDiscount (price) {
      return price?.price < price?.regular;
    },
  },
};
</script>
