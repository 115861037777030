export const state = () => ({
  productList: {},
  isLoading: false
});

export const mutations = {
  setProductList (state, data) {
    state.productList = data;
  },
  setLoadingState (state, data) {
    state.isLoading = data;
  }
};

export const actions = {
  async fetchProductsList ({ commit }, props) {
    commit('setLoadingState', true);
    const response = await this.$repositories.products.byCategory(
      props.categoryId,
      props.culture,
      props.itemsPerPage,
      props.currentPage,
      props.facets,
      props.orderBy,
      props.order,
      props.filters,
      props.startFromFirstPage
    );

    response.items = await this.$kontainer.getPackshots(response?.items);

    commit('setProductList', response);
    commit('setLoadingState', false);
    return response;
  }
};

export const getters = {
  getProductList (state) {
    return state.productList;
  },
  isLoading (state) {
    return state.isLoading;
  }
};
