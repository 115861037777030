<template>
  <ul class="list-unstyled m-0 d-flex">
    <li
      v-for="(item, i) in items"
      :key="i"
      v-bind="$attrs"
      class="m-desktop-navigation__item"
    >
      <base-link
        class="m-desktop-navigation__item-link text-uppercase text-decoration-none d-block"
        :link="item.link.url"
        :class="{'-inpath': activeId === item.id && activeState}"
        :target="item.link.target"
        @mouseover.native="toggleNavigation(item)"
        @click="toggleNavigation(item)"
      >
        {{ item.link.name }}
      </base-link>
    </li>
  </ul>
</template>

<script>
import { clickOutside } from '@/utils/click-outside-directive';
import BaseLink from '@/components/atoms/BaseLink';
import { mapMutations } from 'vuex';

export default {
  name: 'TheDesktopNavigationItem',
  components: {
    BaseLink,
  },
  directives: {
    clickOutside,
  },
  props: {
    items: {
      type: Array,
      default: () => {}
    },
  },
  computed: {
    activeState () {
      return this.$store.getters['navigation/getDesktopActiveState'];
    },
    activeId () {
      return this.$store.getters['navigation/getActiveId'];
    },
    
  },
  methods: {
    ...mapMutations('navigation', [
      'setDesktopActiveState',
      'setActiveId'
    ]),
    toggleNavigation (item) {
      if (item.hasChildren && item.type === 'categories') {
        this.setActiveId(item.id);
        this.setDesktopActiveState(true);
      } else {
        this.setActiveId('');
        this.setDesktopActiveState(false);
      }
    },
    closeNavigation () {
      this.setActiveId('');
      this.setDesktopActiveState(false);
    }
  }
}
</script>