export const gtmDOM = () => {
  if (process.client) {
    $nuxt.$gtm.push({
      event: 'gtm.dom'
    });
  }
};

export const gtmLoad = () => {
  if (process.client) {
    $nuxt.$gtm.push({
      event: 'gtm.load'
    });
  }
};

export const gtmInitConsent = () => {
  if (process.client) {
    $nuxt.$gtm.push({
      event: 'gtm.init_consent'
    });
  }
};

export const trackPageView = ({ user, isLoggedIn }) => {
  if (process.client) {
    let eventObject = {
      event: 'trackPageview',
      loginStatus: isLoggedIn,
    }

    if (isLoggedIn) {
      eventObject.userId = user?.id,
      eventObject.userKey = user?.key
    }
    $nuxt.$gtm.push(eventObject);
  }
};

// Form Submitted
export const trackFormSubmission = (type = '', position = '', userEmail = '') => new Promise((resolve) => {
  if (process.client) {
    $nuxt.$gtm.push({
      'event': 'formSubmission',
      'formType': type,
      'formPosition': position,
      'userEmail': userEmail,
      'eventCallback': function () {
        resolve('SUCCESS: formSubmission event successfully fired');
      },
      'eventTimeout': 500
    });
  }
});
