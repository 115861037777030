<template>
  <li>
    <slot />
  </li>
</template>

<script>
export default {
  name: 'BaseListItem'
};
</script>
