<template>
  <svg-icon :name="'icons/' + cleanUpIconName(icon)" />
</template>

<script>
export default {
  name: 'BaseIcon',
  props: {
    icon: {
      type: [String, Array],
      default: ''
    }
  },
  methods: {
    cleanUpIconName (icon) {
      if (icon.includes('i-')) {
        return icon.replace('i-', '');
      }
      return icon;
    }
  }
};
</script>
