const resource = '/shop-api/basket'
const memberResource = '/members'

export default (ctx, $axios) => ({
  get (culture, userId) {
    return $axios.$get(`${resource}`, {
      params: {
        culture,
        userId
      },
    })
  },
  add (culture, sku, currency, quantity = 1, properties) {

    console.log(currency)

    return $axios.$post(`${resource}/addtobasket`, {
      
        culture,
        sku,
        currency,
        quantity,
        calculateShipping: true,
        properties,
      
    })
  },
  addVariant (culture, sku, variantsku, quantity = 1) {
    return $axios.$get(`${resource}/addtobasket`, {
      params: {
        culture,
        sku,
        variantsku,
        quantity
      }
    })
  },
  updateOrderline (culture, orderlineId, newquantity) {
    return $axios.$get(`${resource}/updateorderline`, {
      params: {
        culture,
        orderlineId,
        newquantity
      }
    })
  },
  clear (culture) {
    return $axios.$get(`${resource}/clearbasket`, {
      params: {
        culture
      }
    })
  },
  deleteMemberBasket (memberId) {
    console.log('deleteMemberBasket', memberId)
    return $axios.$get(`${memberResource}/deletebaskets`, {
      params: {
        memberId
      }
    })
  },
  addVoucher (culture, voucherCode) {
    return $axios.$post('/ucommerce/ucommerceapi/nz/basket/addvoucher', null, {
      params: {
        culture,
        voucherCode
      }
    })
  },
  removeVoucher (culture, voucherCode) {
    return $axios.$post('/ucommerce/ucommerceapi/nz/basket/removevoucher', null, {
      params: {
        culture,
        voucherCode
      }
    })
  },
  setProperty (culture, propertyName, propertyValue) {
    return $axios.$get(`${resource}/setproperty`, {
      params: {
        culture: culture,
        propertyName: propertyName,
        propertyValue: window.encodeURIComponent(propertyValue)
      }
    })
  }
})
