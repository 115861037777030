export const state = () => ({
  isActive: false,
});

export const mutations = {
  setActiveState (state, data) {
    state.isActive = data;
  },
};

export const getters = {
  getActiveState (state) {
    return state.isActive;
  },
};