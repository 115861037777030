<!-- This is intended for Googlebot and other crawlers -->
<template>
  <nav v-if="navigationItems">
    <ul>
      <navigation-tree-item
        v-for="(item, i) in navigationItems"
        :key="i"
        :item="item"
      />
    </ul>
  </nav>
</template>

<script>
import NavigationTreeItem from '@/components/molecules/NavigationTreeItem';
export default {
  name: 'NavigationTree',
  components: {
    NavigationTreeItem
  },
  computed: {
    navigationItems () {
      return this.$store.getters['navigation/getNavigationItems']?.filter(
        item => !item.hideInNavigation
      );
    }
  },
}
</script>