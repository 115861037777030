export const state = () => ({
  wishlist: [],
  isLoading: false
});

export const mutations = {
  setProductWishlist (state, data) {
    state.wishlist = data;
  },
  setLoadingState (state, data) {
    state.isLoading = data;
  }
};

export const actions = {
  async fetchProductWishlist ({ commit }, props) {
    commit('setLoadingState', true);

    const { data } = await this.$axios.get(
      '/productsById?id=' + props.ids, {
        progress: false
      }
    );

    commit('setLoadingState', false);
    commit('setProductWishlist', data);
  }
};

export const getters = {
  getProductWishlist (state) {
    return state.wishlist;
  },
  isLoading (state) {
    return state.isLoading;
  }
};
