const middleware = {}

middleware['auth'] = require('..\\middleware\\auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['content'] = require('..\\middleware\\content.js')
middleware['content'] = middleware['content'].default || middleware['content']

middleware['currency'] = require('..\\middleware\\currency.js')
middleware['currency'] = middleware['currency'].default || middleware['currency']

middleware['theme'] = require('..\\middleware\\theme.js')
middleware['theme'] = middleware['theme'].default || middleware['theme']

export default middleware
