<template>
  <div class="a-badge">
    <!--@slot Use this slot to place content inside the badge-->
    <slot />
  </div>
</template>
<script>
export default {
  name: 'Badge',
};
</script>
