export const state = () => ({
  user: null,
  created: {},
  resetMailSent: {},
  secretIsValid: false,
  passwordIsUpdated: false,
  authToken: null,
  favoriteList: [],
  latestBoughtProductsList: [],
  latestBoughtProductsListFetchedOrFetching: false,
});

export const mutations = {
  setUser (state, data) {
    state.user = data
  },
  setCreated (state, data) {
    state.created = data
  },
  setResetMailSent (state, data) {
    state.resetMailSent = data
  },
  setSecretIsValid (state, data) {
    state.secretIsValid = data?.StatusCode == 1
  },
  setPasswordIsUpdated (state, data) {
    state.passwordIsUpdated = data?.StatusCode == 1
  },
  setAuthToken (state, data) {
    state.authToken = data;
  },
  setFavoriteList (state, data) {
    state.favoriteList = data;
  },
  setlatestBoughtProductsList (state, data) {
    state.latestBoughtProductsList = data;
  },
  setlatestBoughtProductsListFetchedOrFetching (state, data) {
    state.latestBoughtProductsListFetchedOrFetching = data;
  },
};

export const actions = {

  setUser ({ commit, dispatch }, data) {
    commit('setUser', data);

    const currency = data?.accountProperties?.currencyCode ?? 'DKK';
    dispatch ('currency/setCurrentCurrency', currency, { root: true });
  },

  async login ({ commit, dispatch, rootGetters }, { formData }) {
    const data = await this.$repositories.authentication.login(formData.email, formData.password);

    if (data.hasPassword) {

      const cookie = {
        name: data?.authenticationCookie?.name,
        value: data?.authenticationCookie?.value
      }

      commit('setAuthToken', cookie.value);
      dispatch('setUser', data);

      if (formData.stayLoggedIn) {
        this.$cookies.set('auth', cookie.value, {
          path: '/',
          maxAge: 60 * 60 * 24 * 200
        });
      } else {
        this.$cookies.set('auth', cookie.value, {
          path: '/'
        });
      }

      dispatch('site/fetchUserMenu', null, { root: true });
      const currentCulture = rootGetters['site/getCurrentCulture'];
      dispatch('cart/basket/fetchBasket', { culture: currentCulture }, {
        root: true
      });
      dispatch('fetchFavoriteList');
    }

    return data;
  },
  async editMember ({dispatch}, props) {

    const { data } = await this.$axios.get(
      process.env.baseURL + '/umbracoApi/Members/editMember',
      {
        params: {
          billingAddress: props.billingAddress,
          billingName: props.billingName,
          billingEmail: props.billingEmail,
          billingPhone: props.billingPhone,
          shippingAddress: props.shippingAddress,
          shippingName: props.shippingName,
          shippingCompany: props.shippingCompany,
          secret: props.secret,
          userName: props.userName
        }
      }
    )

    let user = Array.isArray(data) ? data[0] : data;

    let staySignedIn = this.$cookies.get('stayLoggedIn') == 1;
    if(staySignedIn) {
      this.$cookies.set('user', user, {
        path: '/',
        maxAge: 60 * 60 * 24 * 200
      });
    } else {
      this.$cookies.set('user', user, {
        path: '/'
      });
    }

    dispatch('setUser', data)
  },
  async createMember ({commit, dispatch}, props) {

    const { data } = await this.$axios.get(
      process.env.baseURL +  '/umbracoApi/Members/createMember',
      {
        params: {
          email: props.email,
          password: props.password
        }
      }
    )

    if(data?.StatusCode == 2) {
      dispatch('setUser', data?.Member)
      this.$cookies.set('user', data?.Member, {
        path: '/'
      })
    }
    commit('setCreated', data)
  },
  async sendResetMail ({commit}, props) {

    const { data } = await this.$axios.get(
      process.env.baseURL +  '/umbracoApi/Members/RequestNewPassword',
      {
        params: {
          email: props.email,
          hostName: props.hostName
        }
      }
    )
    commit('setResetMailSent', data)
  },
  async validateSecret ({commit}, props) {

    const { data } = await this.$axios.get(
      process.env.baseURL + '/umbracoApi/Members/ValidateSecret',
      {
        params: {
          id: props.id,
          secret: props.secret
        }
      }
    )


    commit('setSecretIsValid', data)
  },
  async logout ({ commit, dispatch, rootGetters }) {
    try {
      await this.$repositories.authentication.logout();
    } catch {
      // Die silently
    }

    this.$cookies.remove('auth');
    commit('setAuthToken', null);
    dispatch('setUser', null);
    const currentCulture = rootGetters['site/getCurrentCulture'];
    dispatch('cart/basket/fetchBasket', { culture: currentCulture }, {
      root: true
    });
    commit('setFavoriteList', []);
    commit('setlatestBoughtProductsList', []);
    commit('setlatestBoughtProductsListFetchedOrFetching', false);
  },
  async fetchFavoriteList ({ commit, rootGetters }) {
    const currentCulture = rootGetters['site/getCurrentCulture'];
    const favoriteListRepsonse = await this.$repositories.favorite.getAll(currentCulture);
    commit('setFavoriteList', favoriteListRepsonse);
  },
  async addProductToFavoriteList ({ state, commit }, props) {
    // Updating favorite before to get faster feedback and better UX
    commit('setFavoriteList', [...state.favoriteList, props.productId]);
    const favoriteListRepsonse = await this.$repositories.favorite.add(props.productId);
    commit('setFavoriteList', favoriteListRepsonse);
  },
  async removeProductFromFavoriteList ({ state, commit }, props) {
    // Updating favorite before to get faster feedback and better UX
    commit('setFavoriteList', state.favoriteList.filter((item => item !== props.productId)));
    const favoriteListRepsonse = await this.$repositories.favorite.remove(props.productId);
    commit('setFavoriteList', favoriteListRepsonse);
  },
  async fetchLatestBoughtProductsList ({ state, commit, rootGetters }) {
    if(state.latestBoughtProductsListFetchedOrFetching === false) {
      const currentCulture = rootGetters['site/getCurrentCulture'];
      commit('setlatestBoughtProductsListFetchedOrFetching', true);
      const latestBoughtProductsListRepsonse = await this.$repositories.latestBoughtProducts.getAll(currentCulture);
      commit('setlatestBoughtProductsList', latestBoughtProductsListRepsonse);
    }
  },
};

export const getters = {
  getUser (state) {
    return state.user;
  },
  getAccessToken (state) {
    return state.authToken;
  },
  getCreatedStatus (state) {
    return state.created
  },
  getResetMailSent (state) {
    return state.resetMailSent
  },
  getSecretIsValid (state) {
    return state.secretIsValid
  },
  getPasswordIsUpdated (state) {
    return state.passwordIsUpdated
  },
  isLoggedIn: (state) => {
    return !!state.user;
  },
  isImpersonating: (state, getters) => {
    return (getters.isConsultant || !!state.user?.properties?.customerNumbers) && !!state.user?.accountProperties?.customerNo;
  },
  isConsultant: (state) => {
    return state.user?.properties?.contentTypeAlias === 'consultants';
  },
  isPunchOutUser: (state) => {
    return state.user?.properties?.punchOutUser === 'true';
  },
  hasCustomerNumber: (state) => {
    return !!state.user?.accountProperties?.customerNo;
  },
  getFavoriteList: (state) => {
    return state.favoriteList;
  },
  getLatestBoughtProductsList: (state) => {
    return state.latestBoughtProductsList;
  }
};
