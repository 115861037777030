<template>
  <div class="m-order-summary">
    <payment-information :summary="summary" :show-shipping-delta="showShippingDelta" />
    <div v-if="includesNonrefundableItems" class="text-small mt-4">
      <span>{{ $t('orderIncludesOrderingItems', 'orderIncludesOrderingItems') }}</span><br>
      <span>{{ $t('orderingItemsCannotBeReturned', 'orderingItemsCannotBeReturned') }}</span>
      <base-link :link="termsAndConditionsPage.url">
        {{ termsAndConditionsPage.name }}
      </base-link>
    </div>
    <div v-if="buttonText" class="my-6">
      <slot name="button">
        <base-link
          class="text-decoration-none w-65"
          :link="buttonLink"
        >
          <base-button class="-primary py-4 w-100 px-0">
            {{ buttonText }}
          </base-button>
        </base-link>
      </slot>
    </div>
    <div v-if="$isNotNullOrUndefined(orderlines)" class="my-6">
      <slot name="button">
        <base-button class="a-button -outline-dark semibold py-4 w-100 px-0" @click="printPdf()">
          {{ $t("printBasket", "Print din indkøbskurv") }} 
        </base-button>
      </slot>
    </div>
    <mini-basket-pdf class="d-none" :print-basket="printBasket" :order-summary="summary" :order-lines="orderlines" @print-basket="resetPrintBasket()" />
  </div>
</template>

<script>
import PaymentInformation from '@/components/molecules/ShopCommerce/PaymentInformation';
import BaseLink from '@/components/atoms/BaseLink';
import BaseButton from '@/components/atoms/BaseButton';
import MiniBasketPdf from '@/components/organisms/MiniBasketPDF';

export default {
  name: 'OrderSummary',
  components: {
    PaymentInformation,
    BaseLink,
    BaseButton,
    MiniBasketPdf
  },
  props: {
    summary: {
      default: () => {},
      type: Object
    },
    buttonLink: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    showShippingDelta: {
      type: Boolean,
      default: true
    },
    orderlines: {
      default: () => {},
      type: Array
    }
  },
  data () {
    return {
      printBasket: false
    }
  },
  computed: {
    includesNonrefundableItems () {
      return this.orderlines?.some(orderline => orderline?.customProperties?.orderingItem === 'True' || orderline?.customProperties?.restsalgItem === 'True');
    },
    termsAndConditionsPage () {
      return this.$store.getters['site/getSiteSettings']?.termsAndConditionsPage;
    },
  },
  methods: {
    printPdf () {
      return this.printBasket = true;
    },
    resetPrintBasket () {
      this.printBasket = false;
    }
  }
}
</script>
