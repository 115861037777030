<template>
  <span class="a-clipboard" @click="copy">
    <span v-if="!clicked" ref="val" class="a-clipboard__val">
      <slot />
    </span>
    <span v-else>{{ $t('AddedToClipboard') }}</span>
    <base-icon icon="link" />
  </span>
</template>

<script>
import BaseIcon from '@/components/atoms/BaseIcon';

export default {
  name: 'ClipBoard',
  components: {
    BaseIcon
  },
  data () {
    return {
      clicked: false
    }
  },
  methods: {
    copy (event) {
      const val = this.$refs.val.innerText;
      navigator.clipboard.writeText(val);
      this.clicked = true;

      setTimeout(() => {
        this.clicked = false
      }, 2500);
      
      event.cancelBubble = true;
      event.preventDefault()
    },
  } 
};
</script>
