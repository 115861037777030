import Vue from 'vue';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default () => {
  Vue.prototype.$disableBodyScroll = () =>{
    return disableBodyScroll;
  };

  Vue.prototype.$clearAllBodyScrollLocks = () =>{
    return clearAllBodyScrollLocks;
  };
};
