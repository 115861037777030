import Vue from 'vue'

Vue.filter('formatCurrency', function (value, currency = 'DKK', culture = 'da-dk') {
  if (typeof value !== 'number') {
      return value;
  }

  if (typeof currency !== 'undefined') {
      currency = 'DKK'
  }

  var formatter = new Intl.NumberFormat(culture, {
      style: 'currency',
      currency: currency,
      currencyDisplay: 'code',
      minimumFractionDigits: 2
  });
  return formatter.format(value);
});
