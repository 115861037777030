<template>
  <div v-if="isPunchOutUser">
    <order-summary
      :summary="basket.orderSummary"
      :button-text="$t('punchoutGoToCheckout', 'Gå til godkendelse')"
      @click.native="onClickCheckout"
    />
    <form
      v-if="basket"
      ref="punchOutForm"
      :action="hookUrl"
      method="POST"
      class="d-none"
    >
      <div
        v-for="(orderline, i) in basket.orderLines"
        :key="i"
      >
        <input type="hidden" :name="`NEW_ITEM-VENDORMAT[${i+1}]`" :value="orderline.sku">
        <input type="hidden" :name="`NEW_ITEM-DESCRIPTION[${i+1}]`" :value="orderline.name">
        <input type="hidden" :name="`NEW_ITEM-QUANTITY[${i+1}]`" :value="orderline.quantity">
        <input type="hidden" :name="`NEW_ITEM-UNIT[${i+1}]`" value="EA">
        <input type="hidden" :name="`NEW_ITEM-PRICE[${i+1}]`" :value="orderline.price.priceExVat">
        <input type="hidden" :name="`NEW_ITEM-CURRENCY[${i+1}]`" :value="orderline.price.currencyCode">
        <input type="hidden" :name="`NEW_ITEM-VENDOR[${i+1}]`" :value="34350612">
      </div>
    </form>
  </div>
</template>


<script>
import OrderSummary from '@/components/molecules/ShopCommerce/OrderSummary'
export default {
  name: 'OrderSummaryPunchOut',
  components: {
    OrderSummary
  },
  computed: {
    basket () {
      return this.$store.getters['cart/basket/getBasket'];
    },
    isPunchOutUser () {
      return this.$store.getters['user/user/isPunchOutUser'];
    },
    hookUrl () {
      return this.$cookies.get('hook_url');
    }
  },
  methods: {
    async onClickCheckout () {
      await this.$repositories.punchOut.setStatus(this.basket?.guid)
      this.$refs.punchOutForm.submit();
    }
  },
}
</script>
