<template>
  <div class="a-loader">
    <transition name="fade" mode="out-in">
      <!--@slot Slot for the actual content being loaded -->
      <slot v-if="!loading" />
      <div v-else>
        <!--@slot Use this slot to replace the loader -->
        <slot name="loader">
          <div class="a-loader__spinner">
            Loading...
          </div>
        </slot>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: 'BaseLoader',
  props: {
    /**
     * Shows the loader on top of the existing content
     */
    loading: {
      default: true,
      type: Boolean,
    },
  },
};
</script>
