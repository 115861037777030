<template>
  <div />
</template>

<script>
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
  name: 'MiniBasketPdf',
  props:  {
    printBasket: {
      type: Boolean,
      default: false
    },
    orderLines: {
      default: () => {},
      type: Array
    },
    orderSummary: {
      default: () => {},
      type: Object
    }
  },
  computed: {
    miniBasketPdfHeadline () {
      return `${this.$t('yourShoppingCart', 'Din indkøbskurv')} (${this.orderLines?.length} ${this.goodsTranslation})`;
    },
    goodsTranslation () {
      return this.orderLines?.length > 1
        ? this.$t('goodsPlural', 'varer')
        : this.$t('goodsSingular', 'vare');
    }
  },
  watch: {
    printBasket: function (newVal) {
      if (newVal === true) {
        this.print();
      }
    }
  },
  methods: {
    isUnitPriceOnDiscount (product) {
      return product.price.priceExVat < product.price.regularExVat;
    },
    isOrderlineTotalOnDiscount (product) {
      return product.orderLineSummary.totalPriceExVat < product.orderLineSummary.totalPriceExVatBeforeDiscount;
    },
    print () {
      const pdfDoc = new jsPDF('p', 'pt', 'a4');
        
      //jspdf-autotable
      // Define orderline table data
      const data = this.orderLines.map(orderLine => [
        {
          content: orderLine.name + '\n' + '\n' + this.$t('orderlineId', 'Varenr.') + ' ' + orderLine.sku,
          styles: {
            cellWidth: 'auto',
            avoidPageSplit: true
          }
        },
        orderLine.quantity + ' ' + this.$t('PCS','stk') + (this.isUnitPriceOnDiscount(orderLine) && this.isOrderlineTotalOnDiscount(orderLine) ? '\n' + '\n' + this.$t('beforeDiscount', 'Listepris:') : ''),
        {
          content: orderLine.price.priceExVatFormatted + '\n' + '\n' + (this.isUnitPriceOnDiscount(orderLine) ? orderLine.price.regularExVatFormatted : ''),
          styles: {
            cellWidth: 'auto',
            avoidPageSplit: true,
          }
        },
        {
          content: orderLine.orderLineSummary.totalPriceExVatFormatted + '\n' + '\n' + (this.isOrderlineTotalOnDiscount(orderLine) ? orderLine.orderLineSummary.totalPriceExVatBeforeDiscountFormatted : ''),
          styles: {
            cellWidth: 'auto',
            avoidPageSplit: true
          }
        }
      ]);

      // create cell paddings
      const cellPadding = { left: 5, right: 5, top: 15, bottom: 15 };
      const columnStyles = {
        0: { cellPadding, halign: 'left' },
        1: { cellPadding, halign: 'right' },
        2: { cellPadding, halign: 'right' },
        3: { cellPadding, halign: 'right' }
      };

      // pdf Header
      pdfDoc.setFontSize(18);
      pdfDoc.text(this.miniBasketPdfHeadline, 40, 70);

      // Add table to PDF document
      pdfDoc.autoTable({
        body: data,
        startY: 100,
        margin: { top: 20, bottom: 20 },
        styles: {
          fontSize: 12,
          fontStyle: 'normal',
          lineHeight: 5
        },
        columnStyles
      });

      // draw a line after basket products
      const tableHeight = pdfDoc.lastAutoTable.finalY;
      pdfDoc.setLineWidth(1);
      pdfDoc.line(40, tableHeight, pdfDoc.internal.pageSize.getWidth() - 40, tableHeight);
        

      // add payment info to a new table
      const paymentData = [
        [
          { content: this.$t('TotalBeforeVat', 'I alt (Inkl. rabat)'), styles: { cellWidth: 'auto', avoidPageSplit: true, fillColor: false } },
          { content: this.orderSummary?.totalPriceExShippingExVatFormatted, styles: { cellWidth: 'auto', avoidPageSplit: true,fillColor: false } },
        ],
        [
          { content: this.$t('ShippingTotal', 'Fragt'), styles: { cellWidth: 'auto', avoidPageSplit: true } },
          { content: this.orderSummary?.shippingPriceExVatFormatted, styles: { cellWidth: 'auto', avoidPageSplit: true } },
        ],
        [
          { content: this.$t('VAT', 'Moms'), styles: { cellWidth: 'auto', avoidPageSplit: true, fillColor: false } },
          { content: this.orderSummary?.totalVatFormatted, styles: { cellWidth: 'auto', avoidPageSplit: true, fillColor: false } },
        ],
        [
          { content: this.$t('priceTotal','Total'), styles: { cellWidth: 'auto', avoidPageSplit: true, fontSize: 18, fontStyle: 'bold' } },
          { content: this.orderSummary?.totalPriceFormatted, styles: { cellWidth: 'auto', avoidPageSplit: true, fontSize: 18, fontStyle: 'bold' } },
        ],
        [
          { content: ' ', styles: { fillColor: false } },
          { content: this.$t('includingVat','Inkl. moms'), styles: { cellWidth: 'auto', avoidPageSplit: true, fillColor: false } },
        ],
      ];

        // add the payment data table to the PDF
        pdfDoc.autoTable({
          body: paymentData,
          startY: pdfDoc.lastAutoTable.finalY + 30,
          styles: {
            fontSize: 12,
            fontStyle: 'normal'
          },
          cellPadding: { left: 5, right: 5, top: 20, bottom: 20 },
          columnStyles: {
            1: {
              halign: 'right',
              cellPadding: { left: 5, right: 5, top: 10, bottom: 10 }
            },
            0: {
              cellPadding: { left: 5, right: 5, top: 10, bottom: 10 }
            }
          }
        });

        const pdfBlob = pdfDoc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob, {type: 'application/pdf'});
        
        // Check the user agent to determine the browser
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        
        if (isSafari) {
          // For Safari, just redirect to the PDF viewer
          window.location.href = pdfUrl;
        } else {

          // For Chrome and other browsers, open a new window and initiate print
          const printWindow = window.open(pdfUrl);
          printWindow?.print();
        }

      this.$emit('print-basket');
    }
  }
}
</script>
