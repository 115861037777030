const resource = '/bronnum/oci'

export default (ctx, $axios) => ({
  sendBasket (hookUrl, basket) {
    return $axios.$post(`${resource}/order`, basket, {
      params: {
        hookUrl
      }
    });
  },
  setStatus (basketGuid) {
    return $axios.$get(`${resource}/order/changestatus`, {
      params: {
        basketId: basketGuid
      }
    })
  },
  getByToken (accessToken) {
    return $axios.$get(`${resource}/get/bytoken`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },
})
