const resource = '/authentication'

export default (ctx, $axios) => ({
  login (email, password) {
    return $axios.$post(`${resource}/login`, { email, password });
  },
  logout () {
    return $axios.$get(`${resource}/logout`);
  },
  isLoggedIn () {
    // $axios.defaults.timeout = 120000; // 2 mins.
    // $axios.defaults.proxyTimeout = 120000; // 2 mins.

    return $axios.$get(`${resource}/isloggedin`);
  },
  changePassword (formData) {
    return $axios.$post(`${resource}/changepassword`, {
      OldPassword: formData.currentPassword,
      NewPassword: formData.newPassword,
      NewPasswordConfirmation: formData.newPasswordConfirm
    });
  },
  resetPassword (culture, email) {
    return $axios.$post(`${resource}/resetpassword`, {
      email
    }, {
      params: {
        culture,
      }
    });
  },
  isSecretValid (email, secret) {
    return $axios.$get(`${resource}/istokenvalid/${email}/${secret}`);
  },
  recoverPassword (email, secret, formData) {
    return $axios.$post(`${resource}/recoverpassword`, {
      NewPassword: formData.newPassword,
      NewPasswordConfirmation: formData.newPasswordConfirm,
    }, {
      params: {
        email,
        secret,
      }
    });
  }
})
