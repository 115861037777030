import Vue from 'vue';
import vuexI18n from 'vuex-i18n/dist/vuex-i18n.umd.js';

export default async ( ctx ) => {
  const store = ctx.store;
  let siteLang = '';

  Vue.use( vuexI18n.plugin, store );

  try {
    const { data } = await ctx.$repositories.translation.get(ctx.route.fullPath);

    // Setting da-dk as fallback culture
    siteLang = data.context.culture !== null ? data.context.culture : 'da-dk';

    store.commit( 'site/setCurrentCulture', siteLang );

    if(data.result.length) {
      let cultures = Object.keys(data.result[0]?.translations);
      store.commit( 'site/setLanguages', cultures);
      cultures.forEach( culture => {
        let translations = {};
        data.result.forEach( el => {
          if ( Object.keys(el.translations).length > 0 ) {
            translations[el.key] = el.translations[culture];
          }
        } );
        Vue.i18n.add( culture, translations );
      });
    }

  } catch ( error ) {
    console.error( error );
  }

  Vue.i18n.set( siteLang );
};