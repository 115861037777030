export default (ctx, inject) => {
  const getPackshots = async (products, imagesOnly = false) => {
    // Create an array of skus
    let skus = products?.map(product => product.sku);
    let productAssets = [];
    // Request assets for each sku
    // check if there are more then 50 skus
    if(skus.length > 50) {
      const splitSize = 50;
      for (let i = 0; i < skus.length; i += splitSize) {
        const splitParts = skus.slice(i, i + splitSize);
        // split fetch request in parts
        const requests = ctx.$repositories.assets.bySkus(splitParts)
        const results = await Promise.all([requests]);
        
        if(results[0]) {
          productAssets = productAssets.concat(results[0]);
        }
      }
      // else fetch all assets at once
    } else {
      productAssets = await ctx.$repositories.assets.bySkus(skus);
    }
    // Remove "null" assets
    productAssets = productAssets ? productAssets?.filter(asset => !!asset) : [];
    // Map an image to each product
    let p = products?.map(product => {
      let productAsset = productAssets?.find(asset => asset.sku === product?.sku);
      let packshots = productAsset?.data?.pack ? productAsset?.data?.pack?.map(img => {
        return {
          url: `${process.env.kontainerUrl}${img.url}`,
          name: product.title ?? product.name,
          sku: product.sku
        }
      }) : null;

      product.images = packshots
      return imagesOnly ? packshots : product;
    })

    return imagesOnly ? p.flat() : p;
  }
  // Inject ...
  inject('kontainer', { getPackshots })
}