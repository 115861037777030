<template>
  <div class="m-stock-status">
    <div v-if="stock" class="m-stock-status__content" :data-stock="parseInt(stock.amount)">
      <span v-if="stock.amount > 0 && !showDeliveryOnly" class="m-stock-status__text">+{{ stock.amount }} {{ $t('InStock', 'på lager') }}</span>
      <span v-else class="m-stock-status__text">{{ getDeliveryText() }}</span>
      <!-- 
        Input field to subscribe for the back in stock email.
        Only on the product detail page 
      -->
      <div v-if="showSubscribe" class="mt-6">
        <p> {{ $t('stockNotification', 'stockNotification') }}</p>
        <div class="d-flex align-items-start">
          <base-input
            v-model="email"
            v-validate="'required|email'"
            :required="true"
            placeholder="Email"
            type="text"
            name="stock-email"
            :error-message="
              $t('checkoutEmailErrorMsg', 'Indtast venligst email')
            "
            :show-feedback-icon="true"
            :aria-label="$t('checkoutEmail', 'Indtast email')"
            class="mb-0"
          />
          <base-button class="m-stock-status__subscribe-button a-button -secondary -no-outline w-100 ml-4" @click.native="subscribeForEmail">
            <base-loader v-if="isLoading" />
            <base-icon v-else-if="showTick" class="margin-0-auto text-white " icon="check" />
            <span v-else>{{ $t('submit', 'submit') }}</span>
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/atoms/BaseInput.vue'
import BaseButton from '@/components/atoms/BaseButton.vue'
import BaseIcon from '@/components/atoms/BaseIcon.vue'
import BaseLoader from '@/components/atoms/BaseLoader.vue'

export default {
  name: 'Stock',
  components: {
    BaseInput,
    BaseButton,
    BaseIcon,
    BaseLoader
  },
  props: {
    sku: {
      type: String,
      default : '',
    },
    showDeliveryOnly: {
      type: Boolean,
      default: false
    },
    isOrderingItem: {
      type: Boolean,
      default: false
    },
    deliveryTime: {
      type: Number,
      default: null
    },
    allowToSubscribe: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      stock: null,
      email: '',
      isLoading: false,
      showTick: false,
    }
  },
  computed: {
    culture () {
      return this.$store.getters['site/getCurrentCulture'];
    },
    outOfStock () {
      return this.stock && this.stock.amount === 0;
    },
    showSubscribe () {
      return this.allowToSubscribe && (this.stock && this.stock.amount === 0 && !this.isOrderingItem);
    }
  },
  async mounted () {
    try {
      this.stock = await this.$repositories.stock.getStock(this.sku, this.culture);
      // pass the stock to the parent component
      this.$emit('stock-level', this.stock.actualAmount);
    } catch {
      console.log('$repositories.stock.getStock')
    }
  },
  methods: {
    getDeliveryText () {
      const orderingItem = this.$t('OrderingItem', 'Bestillingsvare')
      const outOfStock = this.$t('OutOfStock', 'Midlertidig udsolgt')
      const expectDeliveryTime = this.$t('ExpectDeliveryTime', 'Forventet leveringstid')
      const weekdays = this.$t('Weekdays', 'hverdage')

      if (this.isOrderingItem) {
        if (this.isOrderingItem && this.deliveryTime <= 21) {
          return `${orderingItem} - ${expectDeliveryTime} ${this.deliveryTime} ${weekdays}`
        }
        else if (this.isOrderingItem && this.deliveryTime > 21) {
          return orderingItem
        }
        else {
          return this.stock.deliveryTime
        }
      } else {
        if(this.outOfStock && this.deliveryTime <= 14) {
          return `${outOfStock} - ${expectDeliveryTime} ${this.deliveryTime} ${weekdays}`
        } 
        else if (this.outOfStock && this.deliveryTime > 14) {
          return outOfStock
        }
        else {
          return this.stock.deliveryTime
        }
      }
    },
    subscribeForEmail () {
      this.$validator.validateAll().then(async result => {
        if (result && this.email) {
          this.isLoading = true;

          try {
            await this.$repositories.stock.getStockNotification(this.email, this.sku);
            //reset input field
            this.email = '';
            this.$validator.reset();

            this.showTick = true;
            this.isLoading = false;
            setTimeout(() => {
              this.showTick = false;
            }, 2000);
          } catch {
            console.log('Error in $repositories.stock.getStockNotification')
          }
        }
      });
    },
  }
};
</script>
