<template>
  <li v-if="item.link">
    <base-link
      :link="item.link.url"
      :target="item.link.target"
      :title="item.link.name"
    >
      {{ item.link.name }}
    </base-link>
    <ul v-if="children">
      <navigation-tree-item
        v-for="(child, i) in children"
        :key="i"
        :item="child"
      />
    </ul>
  </li>
</template>


<script>
import BaseLink from '@/components/atoms/BaseLink';
export default {
  name: 'NavigationTreeItem',
  components: {
    BaseLink
  },
  props: {
    item: {
      required: true,
      type: Object
    },
  },
  computed: {
    children () {
      return this.item?.children?.filter(
        item => !item.hideInNavigation
      );
    },
  }
}
</script>