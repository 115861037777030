const resource = '/ac-api'

export default (ctx, $axios) => ({
  // Create or update contact
  // https://developers.activecampaign.com/reference#create-or-update-contact-new
  syncContact (firstName, lastName, email, phone='') {
    return $axios.$post(`${resource}/contact/sync`, {
      contact: {
        email,
        firstName,
        lastName,
        phone,
      },
    });
  },
  // Update list status for a contact
  // https://developers.activecampaign.com/reference#update-list-status-for-contact
  syncSubscription (listId, contactId, subscribeStatus=1) { // 1 = subscribing, 2 = unsubscribing
    return $axios.$post(`${resource}/contactLists`, {
      contactList: {
        list: listId,
        contact: contactId,
        status: subscribeStatus
      }
    });
  },
})
