const resource = '/shop-api/favorite'

export default (ctx, $axios) => ({
  getAll (culture) {
    return $axios.$get(`${resource}/getall`, {
      params: {
        culture
      }
    });
  },
  add (productId) {
    return $axios.$post(`${resource}/add`, null, {
      params: {
        productid: productId
      }
    });
  },
  remove (productId) {
    return $axios.$post(`${resource}/remove`, null, {
      params: {
        productid: productId
      }
    });
  },
})
