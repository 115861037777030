export const clickOutside = {
  bind (el, binding) {
    el._outsideClickHandler = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.name = 'click-outside';
        binding.value(event, el);
        event.stopPropagation();
      }
    };

    document.addEventListener('click', el._outsideClickHandler);
  },
  unbind (el) {
    document.removeEventListener('click', el._outsideClickHandler);
    el._outsideClickHandler = null;
  },
};