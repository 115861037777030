<template>
  <div>
    <browse-happy-alert v-if="browserIsNotSupported" />
    <div class="content container px-0 px-md-4">
      <component
        :is="pageTemplate"
        v-if="pageTemplate && content.nestedContent"
        :content="content"
      />
    </div>
  </div>
</template>

<script>
const components = {
  fourOFour: () => import('@/components/templates/FourOFour'),
  fiveOx: () => import('@/components/templates/FiveOx'),
  BrowseHappyAlert: () => import('@/components/organisms/BrowseHappyAlert'),
  contentPage: () => import('@/components/templates/ContentPage')
};
export default {
  name: 'Error',
  components,
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    content () {
      return this.$store.getters['content/content/getContent']?.content;
    },
    meta () {
      return this.$store.getters['content/content/getContent']?.meta;
    },
    pageTemplate () {
      if (this.meta?.template) {
        return this.meta?.template;
      } else {
        console.warn(`The template for '${this.meta?.template}' was not found.`);
        return 'contentPage'; // This is just a default for now
      }
    },
    browserIsNotSupported () {
      return this.$store.getters['site/getBrowserSupportStatus'];
    }
  },
  head () {
    return {
      title: this.error.statusCode,
      meta: [
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex, nofollow'
        }
      ]
    };
  }
}
</script>
