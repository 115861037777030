export const state = () => ({
  currencies: [],

  selected: 'DKK'
});

export const mutations = {
  setCurrencies (state, data) {
    state.currencies = data;
  },
  setCurrentCurrency (state, data) {
    state.selected = data;
  },
};

export const actions = {
  setCurrentCurrency ({ commit }, currency) {
    commit('setCurrentCurrency', currency);
  }
};

export const getters = {
  getCurrencies (state) {
    return state.currencies;
  },
  getCurrentCurrency (state) {
    return state.selected;
  },
};