<template>
  <ul class="list-unstyled">
    <slot />
  </ul>
</template>

<script>
export default {
  name: 'BaseList'
};
</script>
