
export default async function (ctx) {
  if (process.server) {
    try {
      const authQueryToken = ctx.query.token;
      const authCookie = ctx.$cookies.get('auth');
      const authToken = authQueryToken || authCookie;

      const punchOutQueryHookUrl = ctx.query.hook_url;
      const punchOutHookUrlCookie = ctx.$cookies.get('hook_url');
      const hookUrl = punchOutQueryHookUrl || punchOutHookUrlCookie;

      if (authQueryToken && punchOutQueryHookUrl) {
        ctx.$cookies.set('auth', authQueryToken);
        ctx.$cookies.set('hook_url', punchOutQueryHookUrl);
      }

      if (authToken) {
        // If there's a hookUrl - Then use the punchOut getByToken endpoint
        const userData = hookUrl ? await ctx.$repositories.punchOut.getByToken(authToken) : await ctx.$repositories.users.getByToken(authToken);
        ctx.store.dispatch('user/user/setUser', userData);
        ctx.store.commit('user/user/setAuthToken', authToken);
      }
    } catch {
      // Try logging out
      ctx.store.dispatch('user/user/logout');
    }
  }

  return ctx;
}
