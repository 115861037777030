<template>
  <div class="o-product-card">
    <span
      class="o-product-card__wrapper d-flex text-decoration-none text-body"
    >
      <base-link
        :link="productLink"
        class="o-product-card__link"
        @click.native="onProductClick"
      >
        <div class="o-product-card__image-wrapper">
          <div v-if="product.images && product.images.length">
            <base-image
              v-for="(picture, key) in product.images.slice(0, 2)"
              :key="key"
              class="o-product-card__picture"
              :url="picture.url"
              :alt-text="picture.name"
              mode="pad"
              :center="picture.focalPoint"
              further-options="&bgcolor=ffffff"
              :width="235"
              :height="189"
              :ratio="235/189"
            />
          </div>
          <base-image
            v-else
            class="o-product-card__image"
            :url="placeholder.url"
            :alt-text="placeholder.name"
            :center="placeholder.focalPoint"
            further-options="&bgcolor=ffffff"
            :width="235"
            :height="189"
            :ratio="235/189"
          />
          <badge
            v-if="isProductOnDiscount && !showAdvertisingCode"
            class="o-product-card__badge bg-primary"
          >
            {{ $t("ProductCard_Discount_Badge", "Tilbud") }}
          </badge>
          <badge
            v-if="isSki"
            class="o-product-card__badge bg-primary"
            :class="{'a-badge--offset': isProductOnDiscount && !showAdvertisingCode}"
          >
            {{ $t("ProductCard_Ski", "SKI") }}
          </badge>
          <badge
            v-if="showAdvertisingCode"
            class="o-product-card__badge bg-primary"
            :class="{'a-badge--offset': isSki}"
          >
            {{ $t(`ProductCard_Discount_${advertisingCode}`, advertisingCode) }}
          </badge>
          <base-icon
            v-if="showCareIcon"
            icon="care-icon"
            class="o-product-card__care-icon a-care-icon p-3"
          />
        </div>
        <div class="o-product-card__info">
          <slot name="title">
            <div class="o-product-card__brand bold small">{{ $isNotNullOrUndefined(getPropertyValue('brand')) ? getPropertyValue('brand') : '' }}</div>
            <p v-if="$isNotNullOrUndefined(getPropertyValue('description1'))" class="o-product-card__title h5">
              {{ getPropertyValue('description1') }}
            </p>
          </slot>
          <div class="o-product-card__description">
            <div v-if="$isNotNullOrUndefined(getPropertyValue('description2'))">{{ getPropertyValue('description2') }}</div>
            <div v-if="$isNotNullOrUndefined(getPropertyValue('description3'))">{{ getPropertyValue('description3') }}</div>
            <div
              v-if="$isNotNullOrUndefined(product.sku)"
              class="mt-2"
            >{{ $t("productId", "Varenr.") }} <clip-board>{{ product.sku }}</clip-board></div>
          </div>
        </div>
        <div v-if="energyLabel" class="o-product-card__energy-label">
          <base-link
            :link="energyLabelDownload.link"
            target="_blank"
            class="o-product-card__energy-link"
          >
            <base-image
              :url="`/energy-labels/${energyLabel.toLowerCase().replace(/\+/g, 'p')}.png`"
              class="o-product-card__energy-picture"
              image-class="o-product-card__energy-image"
              :width="65"
              :height="50"
              :include-cdn="false"
              :is-lazy-load="false"
            />
          </base-link>
        </div>
        <div class="o-product-card__price mt-auto">
          <stock :sku="product.sku" :delivery-time="deliveryTime" :is-ordering-item="isOrderingItem" @stock-level="getStockLevel" />
          <slot name="price">
            <price-user
              v-if="isLoggedIn"
              class="o-product-card__price-value"
              :sku="product.sku"
              :is-product-on-discount="isProductOnDiscount"
              @price-updated="priceUpdated"
            />
            <price
              v-else-if="product.price && product.price.price > 0"
              class="o-product-card__price-value"
              :price="product.price"
              :is-product-on-discount="isProductOnDiscount"
              :is-lavpris="advertisingCode && advertisingCode === 'LAVPRIS'"
            />
          </slot>
          <span v-if="product.orderType" class="o-product-card__type">{{ product.orderType }}</span>
        </div>
        <div v-if="isRestSalg" class="o-product-card__while-available">
          {{ $t("ProductCard_AvailableWhileInStock", "Så længe laver haves") }}
        </div>

      </base-link>
      <div v-if="showAddToCartButton" class="o-product-card__content">
        <span v-if="showStockWarning" class="o-product-card__stock-warning pb-2"> 
          {{ stockWarningMessage }}
        </span>
        <div class="d-flex align-items-center mt-auto" :class="{ 'invisible': product.price && product.price.price === 0 && !isVivaldiUser}">
          <quantity-input-compact
            :id="product.id"
            :class="[isInProductSlider ? 'mr-2 -product-slider' : 'mr-4']"
            :min-quantity="kolliNumber"
            :max-quantity="stockLevel"
            :only-stock-quantity="isRestSalg"
            :kolli="kolliNumber"
            @quantity-change="onQuantityChange"
            @show-stock-warning="checkInputValue"
          />
          <add-to-cart
            :class="{ '-product-slider': isInProductSlider }"
            :sku="product.sku"
            :quantity="quantity"
            :stock-level="stockLevel"
            :kolli-number="kolliNumber"
            :is-ordering-item="isOrderingItem"
            :is-restsalg-item="isRestsalg"
            :disabled="product.price && product.price.price === 0 && !isVivaldiUser"
          />
        </div>
      </div>
    </span>
    <div class="o-product-card__action-icons">
      <base-icon
        v-if="isProductOnLatestBoughtProductsList"
        icon="latestbought"
        class="action-icons__icon text-body"
      />
      <slot name="wishlist-icon">
        <wishlist-button
          v-if="hasCustomerNumber"
          class="o-product-card__wishlist action-icons__icon"
          :product-id="product.sku"
        />
      </slot>
    </div>
  </div>
</template>
<script>
import ClipBoard from '@/components/atoms/ClipBoard'
import Badge from '@/components/atoms/Badge'
import Price from '@/components/atoms/Price';
import PriceUser from '@/components/atoms/PriceUser'
import BaseImage from '@/components/atoms/BaseImage'
import QuantityInputCompact from '@/components/molecules/QuantityInputCompact'
import BaseLink from '@/components/atoms/BaseLink'
import LoginMixin from '@/mixins/loginMixin'
import WishlistButton from '@/components/molecules/ShopCommerce/WishlistButton'
import { trackProductClick, trackAddToCart } from '@/utils/enhancedEcomTracking';
import AddToCart from '@/components/molecules/AddToCart';
import { getKolliNumber } from '@/utils/ShopCommerce/product-properties';
import EnergyLabel from '@/mixins/energyLabelMixin'
import BaseIcon from '@/components/atoms/BaseIcon';
import Stock from '~/components/molecules/ShopCommerce/StockStatus';

export default {
  name: 'ProductCard',
  components: {
    ClipBoard,
    BaseLink,
    BaseImage,
    Price,
    PriceUser,
    QuantityInputCompact,
    Badge,
    AddToCart,
    WishlistButton,
    BaseIcon,
    Stock
  },
  mixins: [LoginMixin, EnergyLabel],
  props: {
        /**
     * Product image
     * It should be an url of the product
     */
    product: {
      type: Object,
      default () {
        return {};
      },
      required: true
    },
    /**
     * Status of showing badge
     */
    showBadge: {
      type: Boolean,
      default: true,
    },
    /**
     * Status of showing add to cart button
     */
    showAddToCartButton: {
      type: Boolean,
      default: false,
    },
    /**
     * addToCartDisabled status of whether button is disabled when out of stock
     */
    addToCartDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Wether to show wishlist icon or not
     */
    showWishListIcon: {
      type: Boolean,
      default: true,
    },
    isRecommended: {
      type: Boolean,
      default: false,
    },
    isInProductSlider: {
      type: Boolean,
      default: false
    },
    isSearchResult: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      quantity: 1,
      isProductOnDiscount: false,
      stockLevel: null,
      showStockWarning: false,
      queryParams: new URLSearchParams(),
    };
  },
  computed: {
    showCareIcon () {
      return this.product.properties.filter(x => x.name === 'broennum_care' && x.value === 'Ja').length > 0
    },
    placeholder () {
      return this.$store.getters['site/getPlaceholder'];
    },
    kolliNumber () {
      return getKolliNumber(this.product);
    },
    hasCustomerNumber () {
      return this.$store.getters['user/user/hasCustomerNumber'];
    },
    latestBoughtProductsList () {
      return this.$store.getters['user/user/getLatestBoughtProductsList'];
    },
    isProductOnLatestBoughtProductsList () {
      return this.latestBoughtProductsList.includes(this.product.sku);
    },
    user () {
      return this.$store.getters['user/user/getUser'];
    },
    isSki () {
      return this.user?.accountProperties?.sKIAccount === 'true' && this.getPropertyValue('skiitem') === 'TRUE';
    },
    isVivaldiUser () {
      return this.user?.accountProperties?.vivaldi?.toLowerCase() === 'true'
    },
    currency () {
      return this.$store.getters['currency/getCurrentCurrency'];
    },
    advertisingCode () {
      return this.getPropertyValue('advertisingcode');
    },
    isRestSalg () {
      return (this.advertisingCode && this.advertisingCode === 'RESTSALG') ?? false;
    },
    showAdvertisingCode () {
      return (
        this.advertisingCode && 
        this.$t(`ProductCard_Discount_${this.advertisingCode}`) !== `ProductCard_Discount_${this.advertisingCode}` && 
        this.$t(`ProductCard_Discount_${this.advertisingCode}`) !== ''
      ) ? true : false;
    },
    deliveryTime () {
      return parseFloat(this.getPropertyValue('deliverytimeoutofstock'));
    },
    isOrderingItem () {
      return this.getPropertyValue('orderingitem') === 'TRUE';
    },
    isRestsalg () {
      return this.getPropertyValue('advertisingcode') === 'RESTSALG';
    },
    stockWarningMessage () {
      let message = this.$t('StockWarningMessage');
      // find a nummeral in this string and replace it with this.stockLevel
      return message.replace(/\d+/, this.stockLevel);
    },
    productLink () {
      return this.product.url;
    },
  },
  watch: {
    kolliNumber () {
      this.quantity = this.kolliNumber;
    },
  },
  async mounted () {
    this.quantity = this.kolliNumber;
    this.isProductOnDiscount = this.isPriceOnDiscount(this.product.price);

    if (this.hasCustomerNumber) {
      this.$store.dispatch('user/user/fetchLatestBoughtProductsList')
    }
  },
  methods: {
    getStockLevel (stock) {
      this.stockLevel = stock;
    },
    checkInputValue (value) {
      return this.showStockWarning = value;
    },
    onProductClick () {
      this.$emit('click-card')
      trackProductClick(this.product, this.product?.primaryCategoryName);
    },
    onQuantityChange (payload) {
      this.quantity = payload.quantity;
    },
    onAddToCart () {
      trackAddToCart(this.product, this.quantity, this.currency);
    },
    isPriceOnDiscount (price) {
      return price?.price < price?.regular;
    },
    priceUpdated (newPrice) {
      this.product.price = newPrice;
      this.isProductOnDiscount = this.isPriceOnDiscount(newPrice);
    },
    getPropertyValue (propertyName) {
      let item = this.product.properties.find(x => x.name === propertyName && x.value !== '')
      return item?.value
    }
  },
};
</script>
