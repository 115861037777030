export const state = () => ({
  content: {},
  isLoading: false,
  pageUid: '',
  pageId: '',
  isFirstImagePreloaded: false,
  theme: '',
  themePageId: '',
  themeImage: null
});

export const actions = {
  async fetchTheme ({ commit }, props) {
    try {
      let {data} = await this.$repositories.content.getPropertyValue(
        props.pageUid,
        props.culture,
        props.propertyJsonPath,
        props.includeAncestors
      )
      let value = data?.result?.value;
      commit('setThemePageId', data?.result?.foundId)
      commit('setTheme', value ? value.toLowerCase() : undefined)
      return data;
    } catch (error) {
      console.log(error)
    }
    return;
  },
  async fetchThemeImage ({ commit }, props) {
    try {
      let {data} = await this.$repositories.content.getPropertyValue(
        props.pageUid,
        props.culture,
        props.propertyJsonPath,
        props.includeAncestors
      )
      commit('setThemeImage', data?.result)
      return data;
    } catch (error) {
      console.log(error)
    }
    return;
  }
};

export const mutations = {
  setContent (state, data) {
    state.content = data;
  },
  setLoadingState (state, data) {
    state.isLoading = data;
  },
  setPageId (state, data) {
    state.pageId = data;
  },
  setPageUid (state, data) {
    state.pageUid = data;
  },
  setIsFirstImagePreloaded (state, data) {
    state.isFirstImagePreloaded = data;
  },
  setTheme (state, data) {
    state.theme = data;
  },
  setThemePageId (state, data) {
    state.themePageId = data;
  },
  setThemeImage (state, data) {
    state.themeImage = data;
  },
};

export const getters = {
  getContent (state) {
    return state.content;
  },
  getLoadingState (state) {
    return state.isLoading;
  },
  getPageUid (state) {
    return state.pageUid;
  },
  getPageId (state) {
    return state.pageId;
  },
  getIsFirstImagePreloaded (state) {
    return state.isFirstImagePreloaded;
  },
  getTheme (state) {
    return state.theme;
  },
  getThemePageId (state) {
    return state.themePageId;
  },
  getThemeImage (state) {
    return state.themeImage;
  },
};
