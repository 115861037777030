<template>
  <div class="m-quantity-input" :class="{'-large' : isLarge}">
    <button
      class="m-quantity-input__btn"
      @click.stop.prevent="addToQuantity(-1)"
    >
      <base-icon class="m-quantity-input__btn-icon" icon="remove" />
    </button>
    <input
      v-model.number="fieldValue"
      class="m-quantity-input__input"
      type="number"
      @keypress="handleInput"
      @blur="validateInput"
    >
    <button
      class="m-quantity-input__btn"
      @click.stop.prevent="addToQuantity(1)"
    >
      <base-icon class="m-quantity-input__btn-icon" icon="add" />
    </button>
  </div>
</template>

<script>
import BaseIcon from '@/components/atoms/BaseIcon.vue'

export default {
  name: 'QuantityInput',
  components: {
    BaseIcon
  },
  props: {
    quantity: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
    minQuantity: {
      type: Number,
      default: 1,
    },
    maxQuantity: {
      type: Number,
      default: Infinity,
    },
    onlyStockQuantity: {
      type: Boolean,
      default: false,
    },
    isLarge: {
      type: Boolean,
      default: false,
    },
    kolli: {
      type: Number,
      default: 1,
    },
  },
  data () {
    return {
      fieldValue: 1,
      internalQuantity: 1,
    };
  },
  created () {
    if (this.quantity !== 1) {
      this.internalQuantity = this.quantity / this.kolli;
    }

    this.fieldValue = this.internalQuantity * this.kolli;
  },
  methods: {
    addToQuantity (num) {
      const newInternalQuantity = this.internalQuantity + num;
      const newFieldValue = newInternalQuantity * this.kolli;

      // Check if the item is in stock
      const onlyStockQuantity = newFieldValue >= this.minQuantity && (newFieldValue <= this.maxQuantity && this.onlyStockQuantity || this.maxQuantity === 0);
      const isNotOnlyStockQuantity = newFieldValue >= this.minQuantity && !this.onlyStockQuantity;

      if (onlyStockQuantity || isNotOnlyStockQuantity) {
        this.internalQuantity = newInternalQuantity;
        this.fieldValue = newFieldValue;
        this.quantityChange();
      }

      const showStockWarning = newFieldValue > this.maxQuantity && this.maxQuantity > 0;
      this.$emit('show-stock-warning', showStockWarning);
    },
    quantityChange () {
      // Only register change if the field has a value
      if (this.fieldValue !== '' && this.fieldValue > 0) {
        this.$emit('quantity-change', {
          quantity: this.fieldValue,
          id: this.id
        });
      }
    },
    handleInput (event) {
      event = (event) ? event : window.event;
      var charCode = (event.which) ? event.which : event.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        event.preventDefault();
      } else {
        return true;
      }
    },
    validateInput () {
      this.internalQuantity = Math.ceil(this.fieldValue / this.kolli);

      if(this.internalQuantity > this.maxQuantity && this.maxQuantity > 0) {
        if(this.onlyStockQuantity) {
          this.internalQuantity = Math.floor(this.maxQuantity / this.kolli);
        }
        this.$emit('show-stock-warning', true);
      } else {
        this.$emit('show-stock-warning', false);
      }

      this.fieldValue = this.internalQuantity * this.kolli;
      this.quantityChange();
    }
  }
};
</script>
