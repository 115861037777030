<template>
  <div class="layout -blank">
    <div class="pt-4 pt-md-0">
      <div class="container t-receipt-page">
        <div class="row no-gutters bg-gray-100 py-6">
          <div class="col-5 col-md-4 col-xl-2">
            <portal-target v-if="activeLevel >= 1 && isActiveMobile && isMobile" name="logo" />
            <the-logo v-else class="m-logo d-block px-5 " />
          </div>
        </div>
      </div>
    </div>
    <browse-happy-alert v-if="browserIsNotSupported" />
    <nuxt />

    <client-only>
      <portal-target name="modal">
        <!--
        Placeholder for modal content
        -->
      </portal-target>
    </client-only>
  </div>
</template>

<script>
import BrowseHappyAlert from '@/components/organisms/BrowseHappyAlert.vue';
import TheLogo from '@/components/molecules/TheLogo.vue';


export default {
  name: 'Blank',
  components: {
    BrowseHappyAlert,
    TheLogo
  },
  computed: {
    browserIsNotSupported () {   
      return this.$store.getters['site/getBrowserSupportStatus'];
    },
   
  }
};
</script>
