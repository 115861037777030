const resource = '/shop-api/billing'

export default (ctx, $axios) => ({
  set (culture, billingInformation) {
    return $axios.$post(`${resource}/addaddress`, billingInformation, {
      params: {
        culture
      }
    })
  },
})
