const resource = '/api/navigation'

export default (ctx, $axios) => ({
  byUrl (url) {
    if (typeof url === 'undefined' || url === '') {
      url = decodeURIComponent(ctx.route.path)
    }

    return $axios.get(`${resource}`, {
      params: {
        url
      }
    })
  },
  byType (url, type = 'shop') {
    if (typeof url === 'undefined' || url === '') {
      url = decodeURIComponent(ctx.route.path)
    }

    return $axios.get(`${resource}/getbytype/${type}`, {
      params: {
        url
      }
    })
  }
})