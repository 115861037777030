<template>
  <base-icon
    class="m-mobile-navigation-button"
    :icon="isActive ? 'close' : 'menu'" 
    @click.native="toggleMenu"
  />
</template>

<script>
import BaseIcon from '@/components/atoms/BaseIcon.vue';

export default {
  name: 'MobileNavigationButton',
  components: {
    BaseIcon
  },
  props: {
    isActive: {
      requirred: true,
      default: false,
      type: Boolean
    }
  },
  methods: {
    toggleMenu () {
      this.$emit('toggleMenu');
    }
  }
};
</script>
