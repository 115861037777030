export const state = () => ({
  addressList: null
});

export const mutations = {
  setAddressList (state, data) {
    state.addressList = data;
  },
};

export const actions = {
  async fetchAddress ({ commit }, props) {
    let addressList = await Promise.allSettled(props.addressGuids?.map(async addressGuid => {
      let { data } = await this.$repositories.content.byGuid(addressGuid, props.culture); 
      return {
        ...data?.result?.body?.content,
        id: data?.result?.meta?.id
      }; 
    }))
    let newAddressList = addressList?.map(x => x.value)
    commit('setAddressList', newAddressList)
  },
};

export const getters = {
  getAddressList (state) {
    return state.addressList;
  },
};