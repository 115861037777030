const resource = '/shop-api/products'

const remapFilters = (filters) => {
  let filtersObj = {}
  if(filters !== '') {
    filters?.forEach(facet => {
      filtersObj = {
        ...filtersObj, [facet.id] : facet.values
      }
    });
  }
  return filtersObj
};

const getArrayMergeCustomIds = (arr) => {
  let filters = arr.filter(x => !x.customId);

  // Horrible hack..!
  let customFilters = arr.filter(x => x.customId);
  let broennumCareFilters = customFilters.filter(x => x.id === 'broennum_care')
  let advertisingcodeFilters = customFilters.filter(x => x.id === 'advertisingcode')
  let onSaleFilters = customFilters.filter(x => x.id === 'onsale')
  let stockProducts = customFilters.filter(x => x.id === 'orderingitem');
  let idFilters = customFilters.filter(x => x.id === 'id')

  if (customFilters.length) {
    if(broennumCareFilters.length) {
      filters.push({
        id: 'broennum_care',
        values: getArrayRemoveDuplicates(broennumCareFilters.map(x => x.values).flat())
      })
    }
    if(onSaleFilters.length) {
      filters.push({
        id: 'onsale',
        values: getArrayRemoveDuplicates(onSaleFilters.map(x => x.values).flat())
      })
      filters.push({
        id: 'advertisingcode',
        values: {
          'operator': 'contains',
          'negated': true,
          'values': ['LAVPRIS', 'RESTSALG']
        }
      })
    }
    if(stockProducts.length) {
      filters.push({
        id: 'orderingitem',
        values: getArrayRemoveDuplicates(stockProducts.map(x => x.values).flat())
      })
    }
    if(advertisingcodeFilters.length) {
      filters.push({
        id: 'advertisingcode',
        values: getArrayRemoveDuplicates(advertisingcodeFilters.map(x => x.values).flat())
      })
    }
    if(idFilters.length) {
      filters.push({
        id: 'id',
        values: getArrayRemoveDuplicates(idFilters.map(x => x.values).flat())
      })
    }
  }
  return filters
};

const getArrayRemoveDuplicates = (arr) => {
  return arr.filter(function (item, pos) {
    return arr.indexOf(item) == pos;
  })
}


export default (ctx, $axios) => ({
  byId (culture, id) {
    return $axios.$get(`${resource}/${culture}/${id}`)
  },
  byIds (culture, productIds) {
    return $axios.$get(`${resource}/${culture}`, {
      params: {
        productIds
      }
    })
  },

  byCategory (categoryId, culture, itemsPerPage, currentPage, facets, orderBy, order, filters, startFromFirstPage) {
    return $axios.$post(`${resource}/bycategory/${culture}/${categoryId}/${itemsPerPage}/${currentPage}`, {
      order, orderBy, facets, 'filters' : remapFilters(filters)
    }, {
      params: {
        startFromFirstPage
      }
    })
  },
  byQuery (query, culture, itemsPerPage, currentPage, facets, orderBy, order, filters, startFromFirstPage) {
    filters = getArrayMergeCustomIds(filters);
    return $axios.$post('/shop-api/search/products/byquery', {
      order, 
      orderBy, 
      facets, 
      'filters' : remapFilters(filters),
      query: query,
      cultureName: culture,
      itemsPerPage: itemsPerPage,
      pageNumber: currentPage,
      startFromFirstPage
    })
  },
  byTrending (culture, itemsPerPage, currentPage, facets, orderBy, order, filters) {
    return $axios.$post(`${resource}/trending/${culture}/${itemsPerPage}/${currentPage}`, {
      order, orderBy, facets, 'filters' : remapFilters(filters)
    })
  },
  byTrendingCategory (culture, categoryId, itemsPerPage, currentPage, facets, orderBy, order, filters) {
    return $axios.$post(`${resource}/trending/bycategory/${culture}/${categoryId}/${itemsPerPage}/${currentPage}`, {
      order, orderBy, facets, 'filters' : remapFilters(filters)
    })
  },
  byRecentlyBought (culture, itemsPerPage, currentPage, filters = []) {
    return $axios.$post(`${resource}/recentlyboughtbycustomers/${culture}/${itemsPerPage}/${currentPage}`, {
      filters: remapFilters(filters)
    })
  },
  byComplimentary (culture, productId, itemsPerPage, currentPage) {
    return $axios.$post(`${resource}/complementary/byproducts/${culture}/${productId}/${itemsPerPage}/${currentPage}`, {})
  },
  bySubstitutions (culture, productId, itemsPerPage, currentPage) {
    return $axios.$post(`${resource}/substitutions/byproducts/${culture}/${productId}/${itemsPerPage}/${currentPage}`, {})
  },
  byPopular (culture, itemsPerPage, currentPage, facets, orderBy, order, filters, startFromFirstPage) {
    return $axios.$post(`${resource}/popular/${culture}/${itemsPerPage}/${currentPage}`, {
      order, orderBy, facets, 'filters' : remapFilters(filters)
    }, {
      params: {
        startFromFirstPage
      }
    })
  },
})
