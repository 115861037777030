const resource = '/users'
const shopResource = '/shop-api'
const membersResource = '/members'

export default (ctx, $axios) => ({
  getRelations (pageNum, pageSize, query) {
    return $axios.$get(`${resource}/getrelations`, {
      params: {
        pageNum,
        pageSize,
        query,
      }
    });
  },
  saveActiveRelation (accountNo) {
    return $axios.$post(`${resource}/saveactiverelation`, null, {
      params: {
        accountNo,
      }
    });
  },
  getByAccountNumber (accountNumber) {
    return $axios.$get(`${membersResource}/getmembersbyaccountno`, {
      params: {
        accountNumber,
      }
    });
  },
  getByToken (accessToken) {
    return $axios.$get(`${resource}/get/bytoken`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      validateStatus: false
    });
  },
  create (culture, formData, update) {
    return $axios.$post(`${resource}/createOrUpdate`, formData, {
      params: {
        culture,
        update
      }
    });
  },
  getEmployee (culture) {
    return $axios.$get(`${shopResource}/employee/get`, {
      params: {
        culture
      }
    });
  }
})
