const resource = '/kontainer-api/bronnum'

export default (ctx, $axios) => ({
  bySku (sku) {
    let skuEnding = `${sku.slice(-2)}`;
    return $axios.$get(`${resource}/cache/json/${skuEnding}/${sku}.json`);
  },
  bySkus (skus) {
    return $axios.$get(`${resource}/ws/?${getSkuQuery(skus)}`);
  }
})

const getSkuQuery = (skus) => {
  let q = skus.map(sku => {
    return `id[]=${sku}`
  }).join('&');
  return `${q}`;
}
