<template>
  <button
    type="button"
    class="a-button"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <!--@slot Use this slot to place content inside the button.-->
    <slot />
  </button>
</template>
<script>
export default {
  name: 'BaseButton'
};
</script>
