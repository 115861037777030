const resource = '/shop-api/search'

export default (ctx, $axios) => ({
  // Note, there's a limit of 10 suggestions (but you can still request less)
  byQuery (query, culture='da-dk', limit=10) {
    return $axios.$post(`${resource}/suggestions/byquery`, {  
      query: query,
      cultureName: culture,
      itemsPerPage: limit
    });
  },
})