<template>
  <div class="m-mobile-navigation__item">
    <ul class="list-unstyled m-0 m-mobile-navigation__item-list">
      <li v-if="activeLevel >= 1 && activeItem && isFirstItem" class="m-mobile-navigation__item m-mobile-navigation__back">
        <p
          class="  m-0 p-0 align-items-center"
          @click="goBack"
        >
          <base-icon
            class="m-mobile-navigation__icon"
            icon="chevron-left"
          />
        </p>
        <base-link
          class="m-mobile-navigation__item-link -parent mb-0 py-5 ml-3 mr-0 text-decoration-none"
          :link="parentItem.link.url || activeItem.link.url"
        >
          {{ parentItem.link.name || activeItem.link.name }}
        </base-link>
      </li>
      <li v-if="item" class="m-mobile-navigation__item">
        <p
          v-if="item.hasChildren && filterSubItems(item).length"
          class="m-mobile-navigation__item-link d-flex align-items-center mb-0 text-decoration-none"
          :class="{'-top-level': activeLevel === 0}"
          @click="setActive(item)"
        >
          {{ item.link.name }}
          <base-icon
            class="m-mobile-navigation__icon m-mobile-navigation__haschildren"
            icon="chevron-right"
          />
        </p>
        <base-link
          v-else-if="item.link"
          class="m-mobile-navigation__item-link d-flex align-items-center mb-0  text-decoration-none"
          :class="{'-top-level': activeLevel === 0}"
          :link="item.link.url"
        >
          {{ item.link.name }}
        </base-link>
        <div v-if="item && item.hasChildren && activePath.indexOf(item.id) !== -1">
          <div class="m-mobile-navigation__column">
            <mobile-navigation-item
              v-for="(subItem, index) in filterSubItems(item)"
              :key="subItem.id"
              :item="subItem"
              :parent-item="item"
              :class="{'m-mobile-navigation__sub-item' : activeLevel >= 1}"
              :is-last-item="index == item.children.length - 1"
              :is-first-item="index == 0"
            />
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import BaseLink from '@/components/atoms/BaseLink';
import BaseIcon from '@/components/atoms/BaseIcon';

export default {
  name: 'MobileNavigationItem',
  components: {
    BaseLink,
    BaseIcon
  },
  props: {
    item: {
      default () {
        return {};
      },
      requirred: true,
      type: Object
    },
    parentItem: {
      default () {
        return {};
      },
      type: Object
    },
    isLastItem: {
      default: false,
      type: Boolean
    },
    isFirstItem: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    activeLevel () {
      return this.$store.getters['navigation/getActiveLevel'];
    },
    activePath () {
      return this.$store.getters['navigation/getActivePath'];
    },
    activeItem () {
      return this.$store.getters['navigation/getActiveItem'];
    }
  },
  methods: {
    ...mapMutations('navigation', [
      'setActiveLevel',
      'setActivePath',
      'setActiveItem',
      'reduceActivePath',
      ]
    ),
    filterSubItems (item) {
      return item.children.filter(x => x && x?.link?.name && !x.hideInNavigation);
    },
    setActive (item) {
      if (item.hasChildren) {
        if(item.type === 'ucommerceCategoryPage') {
          this.setActiveLevel(item.level +2);
        } else {
          this.setActiveLevel(item.level);
        }
        this.setActivePath(item.id);
        this.setActiveItem(item);
      }
    },
    goBack () {
      this.setActiveLevel(this.activeLevel - 1);
      this.reduceActivePath();
    },
  }
};
</script>
