const resource = '/shop-api/boughtproduct'

export default (ctx, $axios) => ({
  getAll (culture) {
    return $axios.$get(`${resource}/getall`, {
      params: {
        culture
      }
    });
  },
})
