import { getSearchSpots } from '@/utils/spots';

export const state = () => ({
  result: {},
  isLoading: false
});

export const mutations = {
  setResult (state, data) {
    state.result = data;
  },
  setLoading (state, data) {
    state.isLoading = data;
  },
};

export const actions = {
  async fetchResult ({ commit }, props) {
    try {
      commit('setLoading', true)
      let { data } = await this.$repositories.content.byQuery(
        props.query,
        props.culture,
        props.itemsPerPage,
        props.currentPage
      );
      // Map to spots
      data.items = data?.items ? getSearchSpots(data?.items) : null;
      commit('setResult', data)
      commit('setLoading', false)
    } catch (error) {
      console.log(error)
    }
    return;
  },
};

export const getters = {
  getResult (state) {
    return state.result;
  },
  getLoading (state) {
    return state.isLoading;
  },
};
