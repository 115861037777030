<template>
  <div class="o-mini-basket">
    <div v-for="orderline in basket.orderLines" :key="orderline.id">
      <orderline-compact :product="orderline" />
      <hr>
    </div>
  </div>
</template>

<script>
import OrderlineCompact from '@/components/organisms/ShopCommerce/OrderlineCompact'
import { trackBasketViews } from '@/utils/enhancedEcomTracking';

export default {
  name: 'MiniBasket',
  components: {
    OrderlineCompact
  },
  props: {
    trackBasketViews: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    basket () {
      return this.$store.getters['cart/basket/getBasket'];
    }
  },
  created () {
    if(this.trackBasketViews) {    
      trackBasketViews(this.basket?.orderLines);
    }
  },
}
</script>
