<template>
  <base-link
    :link="link.url"
    class="m-spot-page-summary"
    @click.prevent="$emit('clicked', link)"
  >
    <div class="m-spot-page-summary__figure">
      <base-image
        :url="image.length ? image[0].Url : undefined"
        :alt-text="headline"
        :width="244"
        :height="98"
      />
    </div>
    <div class="m-spot-page-summary__content">
      <div class="m-spot-page-summary__headline">
        {{ headline }}
      </div>
      <div class="m-spot-page-summary__text">
        {{ text }}
      </div>
    </div>
  </base-link>
</template>

<script>
import BaseLink from '@/components/atoms/BaseLink';
import BaseImage from '@/components/atoms/BaseImage';
export default {
  name: 'SpotPageSummary',
  components: {
    BaseImage,
    BaseLink
  },
  props: {
    headline: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    image: {
      type: Array,
      default: () => {}
    },
    link: {
      type: Object,
      default: () => {}
    }
  },
}
</script>