export const state = () => ({
  selectedFilters: []
});

export const mutations = {
  resetSelectedFilter (state) {
    state.selectedFilters = [];
  },
  setSelectedFilters (state, data) {
    state.selectedFilters = data;
  }
};

export const getters = {
  getSelectedFilters (state) {
    return state.selectedFilters;
  }
};
