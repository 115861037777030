<template>
  <transition name="fade">
    <div
      v-if="visible"
      class="o-mega-menu pt-2 d-none d-lg-block"
      @mouseleave="closeMenu"
    >
      <div class="o-mega-menu__content">
        <div
          class="o-mega-menu__menu"
          :class="{ '-has-aside': this.$slots.aside }"
        >
          <div class="w-100 px-2 px-md-4 px-lg-8">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <!-- Taps bar -->
                  <div class="m-mega-menu-taps">
                    <div
                      v-for="item in visibleItems"
                      :key="item.id"
                      class="m-mega-menu-taps__tap"
                    >
                      <base-link
                        class="m-mega-menu-taps__tap-link d-block"
                        :class="{'-active': activeTap === item.id || activePath.includes(item.id)} "
                        :link="item.hasChildren ? '' : item.link.url"
                        @click.native="item.hasChildren ? setActiveTap(item.id) : null"
                      >
                        {{ item.link.name }}
                      </base-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <mega-menu-column 
            v-for="item in visibleItems.filter(x => hasVisibleChildren(x))" 
            :key="item.id"
            :class="{'-active' : activeTap === item.id}"
            :items="item.children.filter(x => !x.hideInNavigation)"
            :promotion-cards="item.promotionCards"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import BaseLink from '@/components/atoms/BaseLink';
//import BaseIcon from '@/components/atoms/BaseIcon';
import MegaMenuColumn from '@/components/molecules/MegaMenuColumn.vue';
import { mapMutations } from 'vuex';

export default {
  name: 'TheMegaMenu',
  components: {
    BaseLink,
    MegaMenuColumn,
    //BaseIcon,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default () {
        return [];
      },
      required: true
    }
  },
  data () {
    return {
      activeTap: ''
    }
  },
  computed: {
    visibleItems () {
      return this.items.filter(x => !x.hideInNavigation);
    },
    activePath () {
      return this.$store.getters['navigation/getActivePath'];
    },
  },
  mounted () {
    this.$nextTick(() => {
      this.activeTap = this.visibleItems[0].id;

      this.visibleItems.forEach(item => {
        if (this.activePath.includes(item.id)) this.setActiveTap(item.id);
      });

    });
  },
  methods: {
    ...mapMutations('navigation', [
      'setDesktopActiveState'
    ]),
    setActiveTap (id) {
      this.activeTap = id;
    },

    hasVisibleChildren (item) {
      return item.hasChildren && item.children.filter(x => !x.hideInNavigation).length > 0;
    },

    closeMenu (){
      this.setDesktopActiveState(false);
    },

  }
};
</script>
